import { useMsal } from "@azure/msal-react";
import React, { useEffect, useReducer } from "react";
import {
	getColleagues,
	getDepartment,
	getUserCheck,
	getUserManager,
	graphPhoto,
} from "@/services/Auth.service";
import { useMutation } from "@tanstack/react-query";
import { UserContext } from "./UserContext";
import UserReducer from "./UserReducer";
import {
	GET_MANAGER,
	GET_PROFILE,
	GET_PROFILE_PHOTO,
	GET_USER_CHECK,
	GET_USER_TOKEN,
	MANAGER_DIRECT_REPORTS,
} from "../types";
import {
	BrowserAuthError,
	InteractionRequiredAuthError,
	InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "@/authConfig";

const UserState: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const { instance, accounts, inProgress } = useMsal();

	const initialState = {
		check: null,
		department: null,
		manager: null,
		directReports: [],
		accessToken: null,
		profilePhoto: null,
	};

	const [state, dispatch] = useReducer(UserReducer, initialState);

	const handleAccessTokenReceived = (accessToken: string) => {
		dispatch({ type: GET_USER_TOKEN, payload: accessToken });
		getUserCheckMutation.mutate(accessToken);
		getGraphPhotoMutation.mutate(accessToken);
		getDepartmentMutation.mutate(accessToken);
		getManagerMutation.mutate(accessToken);
	};

	const getUserCheckMutation = useMutation({
		mutationFn: (accessToken: string) => getUserCheck(accessToken),
		onSuccess: (response) => {
			dispatch({ type: GET_USER_CHECK, payload: response });
		},
		onError: (err) => {
			console.log(err);
			// handleUserTokenRenewal();
		},
		retry: false,
	});

	const getGraphPhotoMutation = useMutation({
		mutationFn: (accessToken: string) => graphPhoto(accessToken),
		onSuccess: (response) => {
			dispatch({ type: GET_PROFILE_PHOTO, payload: response });
		},
		onError: (err) => {
			console.log(err);
			// handleUserTokenRenewal();
		},
		retry: false,
	});

	const getDepartmentMutation = useMutation({
		mutationFn: (accessToken: string) => getDepartment(accessToken),
		onSuccess: (response) => {
			dispatch({ type: GET_PROFILE, payload: response });
		},
		onError: (err) => {
			console.log(err);
			// handleUserTokenRenewal();
		},
		retry: false,
	});

	const getManagerMutation = useMutation({
		mutationFn: (accessToken: string) => getUserManager(accessToken),
		onSuccess: (response) => {
			dispatch({ type: GET_MANAGER, payload: response.data });
			getDirectReportsQuery.mutate({
				accessToken: response.token,
				manager: response.data.userPrincipalName,
			});
		},
		onError: (err) => {
			console.log(err);
			// handleUserTokenRenewal();
		},
		retry: false,
	});

	const getDirectReportsQuery = useMutation({
		mutationFn: ({
			accessToken,
			manager,
		}: {
			accessToken: string;
			manager: string;
		}) => getColleagues(accessToken, manager),
		onSuccess: (response) => {
			dispatch({ type: MANAGER_DIRECT_REPORTS, payload: response.value });
		},
		onError: (err) => {
			console.log(err);
			// handleUserTokenRenewal();
		},
	});

	const handleUserTokenRenewal = () => {
		const accessTokenRequest = {
			...loginRequest,
			account: accounts[0],
		};
		if (inProgress === InteractionStatus.None) {
			instance
				.acquireTokenSilent(accessTokenRequest)
				.then((accessTokenResponse) => {
					// Acquire token silent success
					let accessToken = accessTokenResponse.accessToken;
					// Call your API with token
					handleAccessTokenReceived(accessToken);
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						instance.acquireTokenRedirect(accessTokenRequest);
					}
					//? Handle the user redirecting to the login page manually
					if (error instanceof BrowserAuthError) {
						window.location.href = "/";
					}
					console.log(error);
				});
		}
	};

	useEffect(() => {
		handleUserTokenRenewal();
	}, [instance, accounts, inProgress]);

	return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
};

export default UserState;
