import { Action, getCaseActions } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import React, { useState } from "react";
import DataTable from "./table/DataTable";
import ColumnHeader from "./table/ColumnHeader";
import { Skeleton } from "./ui/skeleton";
import DisplayEvent from "./dialog/DisplayEvent";
import { Button } from "./ui/button";
import { convertDateToText } from "@/constants";
import CreateUpdate from "./dialog/CreateUpdate";

const CaseActions: React.FC<{ id: number; accessToken: string }> = ({
	id,
	accessToken,
}) => {
	const [showEventDetails, setShowEventDetails] = useState(false);
	const [currentEvent, setCurrentEvent] = useState<Action | null>(null);
	const caseActions = useQuery({
		queryKey: ["case", "actions", id],
		queryFn: () => getCaseActions(accessToken, id),
	});

	const displayEvent = (action: Action) => {
		setShowEventDetails(true);
		setCurrentEvent(action);
	};
	return (
		<>
			<DisplayEvent
				open={showEventDetails}
				setOpen={setShowEventDetails}
				action={currentEvent}
			/>
			<h2 className="uppercase text-gray-700 font-bold tracking-wider">
				Case Events
			</h2>
			<hr className="mb-4" />
			<div className="pb-4 flex justify-end">
				<CreateUpdate id={id} />
			</div>
			<div className="mb-6">
				{caseActions.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 10 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : caseActions.data ? (
					<DataTable columns={Columns(displayEvent)} data={caseActions.data} />
				) : (
					<div>No actions found.</div>
				)}
			</div>
		</>
	);
};

export default CaseActions;

const Columns = (
	displayEvent: (action: Action) => void
): ColumnDef<Action>[] => {
	return [
		{
			accessorKey: "actionid",
			header: ({ column }) => <ColumnHeader column={column} title="Event ID" />,
			cell: ({ row }) => (
				<Button variant="link" onClick={() => displayEvent(row.original)}>
					{row.getValue("actionid")}
				</Button>
			),
		},
		{
			accessorKey: "actiondescription",
			header: ({ column }) => (
				<ColumnHeader column={column} title="Event Description" />
			),
			cell: ({ row }) => {
				const event = row.original;
				return (
					<div className="text-sm flex gap-1 items-center">
						<span className="text-xs rounded-full px-1.5 py-0.5 font-medium text-nowrap bg-primary/10 text-primary">
							{event.actiontype}
						</span>
						<p>
							{event.actiondescription.length > 40
								? event.actiondescription.slice(0, 40) + "..."
								: event.actiondescription}
						</p>
					</div>
				);
			},
		},
		{
			accessorKey: "actiondate",
			header: ({ column }) => <ColumnHeader column={column} title="Event Date" />,
		},
		{
			accessorKey: "username",
			header: ({ column }) => <ColumnHeader column={column} title="Created By" />,
		},
		{
			accessorKey: "createdon",
			header: ({ column }) => <ColumnHeader column={column} title="Created On" />,
			cell: ({ row }) => convertDateToText(row.getValue("createdon")),
		},
		{
			accessorKey: "attachments",
			header: ({ column }) => <ColumnHeader column={column} title="Attachments" />,
			cell: ({ row }) => {
				const event = row.original;
				return event.attachments ? event.attachments.split(",").length : 0;
			},
		},
	];
};
