import React from "react";
import ShowImages from "./ShowImages";
import { getFileExtension, imageExtensions, S3_URL } from "@/constants";
import { FaFile, FaFilePdf, FaFileWord } from "react-icons/fa";

type Props = {
	images: string[];
	docs: string[];
};

const CaseAttachments: React.FC<Props> = ({ images, docs }) => {
	return (
		<>
			{images.length > 0 ? <ShowImages images={images} /> : null}
			{docs.length > 0 ? (
				<div className="flex flex-col divide-y">
					{docs.map((doc, index) => (
						<a
							href={`${S3_URL}${doc}`}
							target="_blank"
							rel="noreferrer"
							key={index}
							className="py-3 px-2 hover:rounded-md flex gap-2 hover:bg-blue-50 items-center"
						>
							{getFileExtension(doc) == "pdf" ? (
								<FaFilePdf className="text-2xl text-red-700" />
							) : getFileExtension(doc) == "docx" || "doc" ? (
								<FaFileWord className="text-2xl text-blue-700" />
							) : (
								<FaFile className="text-2xl text-red-700" />
							)}
							<span className="font-medium text-sm">{doc.split("/")[2]}</span>
						</a>
					))}
				</div>
			) : null}
		</>
	);
};

export default CaseAttachments;
