import { Column } from "@tanstack/react-table";
import { cn } from "@/lib/util";
import { Button } from "../ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { HiArrowNarrowDown, HiArrowNarrowUp } from "react-icons/hi";
import { RxEyeNone } from "react-icons/rx";
import { MdSort } from "react-icons/md";

interface DataTableColumnHeaderProps<TData, TValue>
	extends React.HTMLAttributes<HTMLDivElement> {
	column: Column<TData, TValue>;
	title: string;
}

export const ColumnHeader = <TData, TValue>({
	column,
	title,
	className,
}: DataTableColumnHeaderProps<TData, TValue>) => {
	if (!column.getCanSort()) {
		return <div className={cn(className)}>{title}</div>;
	}

	return (
		<div className={cn("flex items-center space-x-2", className)}>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						size="sm"
						className="-ml-3 h-8 data-[state=open]:bg-accent px-2"
					>
						<span>{title}</span>
						{column.getIsSorted() === "desc" ? (
							<HiArrowNarrowDown className="ml-2 h-4 w-4" />
						) : column.getIsSorted() === "asc" ? (
							<HiArrowNarrowUp className="ml-2 h-4 w-4" />
						) : (
							<MdSort className="ml-2 h-4 w-4" />
						)}
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem onClick={() => column.toggleSorting(false)}>
						<HiArrowNarrowUp className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
						Asc
					</DropdownMenuItem>
					<DropdownMenuItem onClick={() => column.toggleSorting(true)}>
						<HiArrowNarrowDown className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
						Desc
					</DropdownMenuItem>

					{column.getCanHide() ? (
						<>
							<DropdownMenuSeparator />{" "}
							<DropdownMenuItem onClick={() => column.toggleVisibility(false)}>
								<RxEyeNone className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
								Hide
							</DropdownMenuItem>
						</>
					) : null}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
};

export default ColumnHeader;
