import { useContext } from "react";
import { EventType, getEventTypes } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "@/context/User/UserContext";
import { ColumnDef } from "@tanstack/react-table";
import ColumnHeader from "../table/ColumnHeader";
import { NavLink } from "react-router-dom";
import { Button } from "../ui/button";
import LawFirmTable from "../table/LawFirmTable";
import { STALE_TIME } from "@/constants";
import CreateClaimType from "../dialog/CreateClaimType";
import CreateActionType from "../dialog/CreateActionType";
import { Skeleton } from "../ui/skeleton";

const ActionTypes = () => {
	const user = useContext(UserContext);

	const eventTypes = useQuery({
		queryKey: ["event_types"],
		queryFn: () => getEventTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});
	return (
		<div>
			<div className="flex justify-between items-center mb-4">
				<div>
					<h1 className="text-3xl font-bold">Event Types</h1>
				</div>
				<CreateActionType user={user} />
			</div>
			<div className="bg-white pb-4 border rounded-md shadow-sm">
				{eventTypes.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 4 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : eventTypes.data ? (
					<LawFirmTable data={eventTypes.data} columns={Columns} />
				) : (
					<p>No data</p>
				)}
			</div>
		</div>
	);
};

export default ActionTypes;

const Columns: ColumnDef<EventType>[] = [
	{
		accessorKey: "actiontypeid",
		header: ({ column }) => <ColumnHeader column={column} title="Law Firm Id" />,
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/claim-type/${row.original.actiontypeid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("actiontypeid")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "actiontype",
		header: ({ column }) => <ColumnHeader column={column} title="Claim Type" />,
	},
	{
		accessorKey: "datacreated",
		header: ({ column }) => <ColumnHeader column={column} title="Date Created" />,
	},
];
