import { z } from "zod";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { UserState } from "@/context/User/UserReducer";
import {
	addPaymentType,
	AddPaymentTypeParams,
} from "@/services/PaymentType.service";

const formSchema = z.object({
	paymenttype: z.string(),
});

const CreatePaymentType: React.FC<{ user: UserState | null }> = ({ user }) => {
	const [open, setOpen] = useState(false);

	const queryClient = useQueryClient();

	const createPaymentType = useMutation({
		mutationKey: ["paymenttype_create"],
		mutationFn: (data: AddPaymentTypeParams) =>
			addPaymentType(user?.accessToken!, data),
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: ["payment_types"],
				exact: true,
				refetchType: "all",
			});
			setOpen(false);
			form.reset();
			toast.success("Success", {
				description: `Payment type ${variables.paymenttype} has been successfully created`,
			});
			createPaymentType.reset();
		},
		onError: (err) => {
			toast.error("Error!", {
				description: "Error adding payment type, try again",
			});
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		console.log(data);
		createPaymentType.mutate(data);
	};

	const disabled = () => {
		if (createPaymentType.isPending) return true;
		if (createPaymentType.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">Add Payment Type</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle className="text-left font-bold text-2xl">
						Add Payment Type
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<FormField
							name="paymenttype"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Payment Type</FormLabel>
									<FormControl>
										<Input type="text" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Button
							type="submit"
							className="mt-4 w-full"
							disabled={createPaymentType.isPending || createPaymentType.isSuccess}
						>
							{createPaymentType.isPending ? "Creating..." : "Create Payment Type"}
						</Button>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default CreatePaymentType;
