import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { HiMenu } from "react-icons/hi";
import { useState } from "react";
import Sidebar from "./Sidebar";
import Logo from "@/media/nation.png";

const MobileSidebar = () => {
	const [open, setOpen] = useState(false);

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild className="lg:hidden">
				<button className="flex gap-2 items-center">
					<HiMenu className="text-2xl" />
					<div className="flex items-center gap-2">
						<div className="h-8">
							<img src={Logo} alt="logo" className="h-full w-full" />
						</div>
						<h3 className="text-xl font-bold">Nation</h3>
					</div>
				</button>
			</SheetTrigger>
			<SheetContent className="p-0" side="left">
				<Sidebar closeSidebar={() => setOpen(false)} />
			</SheetContent>
		</Sheet>
	);
};

export default MobileSidebar;
