import { z } from "zod";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { addLawFirm, AddLawFirmParams } from "@/services/LawFirm.service";
import { UserState } from "@/context/User/UserReducer";

const formSchema = z.object({
	lawfirm: z.string(),
	lawfirmemail: z.string(),
	phoneno: z.string(),
	legalrepresentative: z.string(),
	legalrepresentativephone: z.string(),
	legalrepresentativeemail: z.string(),
});

const CreateLawFirm: React.FC<{ user: UserState | null }> = ({ user }) => {
	const [open, setOpen] = useState(false);

	const queryClient = useQueryClient();

	const createLawFirm = useMutation({
		mutationKey: ["claimtype_create"],
		mutationFn: (data: AddLawFirmParams) => addLawFirm(user?.accessToken!, data),
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: ["law_firms"],
				exact: true,
				refetchType: "all",
			});
			setOpen(false);
			form.reset();
			toast.success("Success", {
				description: `Law Firm ${variables.lawfirm} has been successfully created`,
			});
			createLawFirm.reset();
		},
		onError: (err) => {
			toast.error("Error!", { description: "Error adding law firm, try again" });
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		console.log(data);
		createLawFirm.mutate(data);
	};

	const disabled = () => {
		if (createLawFirm.isPending) return true;
		if (createLawFirm.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">Add Law Firm</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle className="text-left font-bold text-2xl">
						Add Law Firm
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<FormField
							name="lawfirm"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Law Firm</FormLabel>
									<FormControl>
										<Input type="text" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<div className="flex flex-col md:flex-row gap-4">
							<FormField
								name="lawfirmemail"
								render={({ field }) => (
									<FormItem className="flex-1 mb-2">
										<FormLabel>Law Firm Email</FormLabel>
										<FormControl>
											<Input type="email" {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="phoneno"
								render={({ field }) => (
									<FormItem className="flex-1 mb-2">
										<FormLabel>Phone Number</FormLabel>
										<FormControl>
											<Input type="text" {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<FormField
							name="legalrepresentative"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Legal Representative</FormLabel>
									<FormControl>
										<Input type="text" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							name="legalrepresentativephone"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Legal Representative Phone Number</FormLabel>
									<FormControl>
										<Input type="text" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							name="legalrepresentativeemail"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Legal Representative Email</FormLabel>
									<FormControl>
										<Input type="email" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Button
							type="submit"
							className="mt-4 w-full"
							disabled={createLawFirm.isPending || createLawFirm.isSuccess}
						>
							{createLawFirm.isPending ? "Creating..." : "Create Law Firm"}
						</Button>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default CreateLawFirm;
