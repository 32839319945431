import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const getUploadedFile = async (url: string) => {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Error fetching file: ${response.statusText}`);
		}
		const segments = url.split("/");
		const fileName = segments[segments.length - 1];

		const blob = await response.blob();
		const file = new File([blob], fileName, { type: blob.type });

		const dataTransfer = new DataTransfer();
		dataTransfer.items.add(file);
		return dataTransfer.files[0];
	} catch (error) {
		console.error("Error uploading file:", error);
		return null;
	}
};
