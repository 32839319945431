/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect } from "react";
import CaseContext from "../../context/Case/CaseContext";
import { useLocation } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Link } from "react-router-dom";

import {
	S3_URL,
	DateConverter,
	getFileExtension,
	imageExtensions,
	fileExtensions,
	// TimeConverter,
} from "../../constants";
import { Case, CasePayment, CaseUpdate } from "@/context/Case/CaseState";

const CaseDetail = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const caseid = searchParams.get("caseid");
	let casecontext = useContext(CaseContext);
	// const alertContext = useContext(AlertContext);

	const {
		getSingleCase,
		getsinglecase,
		getactions,
		getpayments,
		getPayments,
		getActions,
	} = casecontext;

	useEffect(() => {
		// loadToken()
		getPayments(caseid);
		getSingleCase(caseid);
		getActions(caseid);

		// eslint-disable-next-line
	}, []);

	let loadedpayments = [];
	if (getpayments) {
		loadedpayments = getpayments;
	} else {
		loadedpayments = [];
	}

	let loadedactions = [];
	if (getactions) {
		loadedactions = getactions;
	} else {
		loadedactions = [];
	}

	let loadedcases: Case | null = null;
	if (getsinglecase) {
		loadedcases = getsinglecase[0];
	} else {
		loadedcases = null;
	}

	return (
		<div>
			{getsinglecase && getactions && getpayments && loadedcases ? (
				<div className="row">
					<div className="col-lg-1"></div>
					<div className="col-lg-10 shadow-lg">
						{/* {keys.map((option) => ( */}
						<section>
							<div className="row">
								<div className="col-lg-4"></div>
								<div className="col-lg-4">
									<h1 className="text-center">Case No. {loadedcases.caseno}</h1>
									<hr />
									<h2 className="text-center text-primary">
										{" "}
										{/* <u>{loadedmasterinvoice.invoicetype}</u> */}
									</h2>
								</div>
								<div className="col-lg-4"></div>
							</div>
							<div>
								<div>
									<Table className="table table-primary table-striped table-bordered table-responsive">
										<Thead className="">
											<Tr>
												<Th></Th>
												<Th className="text-center text-primary">Description</Th>
												<Th className="text-center text-primary">|</Th>
												<Th></Th>
												<Th className="text-center text-primary">Description</Th>
											</Tr>
										</Thead>
										<Tbody>
											<Tr>
												<Td className="text-primary">Case Type</Td>
												<Td>{loadedcases.claimtype}</Td>
												<Td className="text-center text-primary">|</Td>
												<Td className="text-primary">Representing Law Firm</Td>
												<Td>{loadedcases.lawfirm}</Td>
											</Tr>
											<Tr>
												<Td className="text-primary">Plaintiff/Petitioner</Td>
												<Td>{loadedcases.complainant}</Td>
												<Td className="text-center text-primary">|</Td>
												<Td className="text-primary">Third Party Advoacate</Td>
												<Td>{loadedcases.defendingadvocate}</Td>
											</Tr>
											<Tr>
												<Td className="text-primary">Filing Date</Td>
												<Td>{DateConverter(loadedcases.filingdate)}</Td>
												<Td className="text-center text-primary">|</Td>
												<Td className="text-primary">Added By</Td>
												<Td>{loadedcases.createdby}</Td>
											</Tr>
											<Tr>
												<Td className="text-primary">Added On</Td>
												<Td>{DateConverter(loadedcases.createdon)}</Td>
												<Td className="text-center text-primary">|</Td>
												<Td className="text-primary">Case Summary</Td>
												<Td>{loadedcases.casedescription}</Td>
											</Tr>
										</Tbody>
									</Table>
									{/* not in table */}
									{/* <div className="row">
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Case Type</h4>
                    <p className="text-center inputborder">{loadedcases.claimtype}</p>
                        </div>
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Representing Law Firm</h4>
                    <p className="text-center inputborder">{loadedcases.lawfirm}</p>
                        </div>
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Representing Law Firm Email</h4>
                    <p className="text-center inputborder">{loadedcases.lawfirmemail}</p>
                        </div>
                    </div> */}

									{/* <div className="row">
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Plaintiff/Petitioner</h4>
                    <p className="text-center inputborder">{loadedcases.complainant}</p>
                        </div>
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Third Party Advoacate</h4>
                    <p className="text-center inputborder">{loadedcases.defendingadvocate}</p>
                        </div>
                        <div className="col-lg-4">
                        <h4 className="text-center text-primary">Filing Date</h4>
                    <p className="text-center inputborder">{DateConverter(loadedcases.filingdate)}</p>
                        </div>
                    </div> */}

									{/* <div className="row">
                    <div className="col-lg-6">
                        <h4 className="text-center text-primary">Added By</h4>
                    <p className="text-center inputborder">{loadedcases.createdby}</p>
                        </div>
                        <div className="col-lg-6">
                        <h4 className="text-center text-primary">Created On</h4>
                    <p className="text-center inputborder">{DateConverter(loadedcases.createdon)}</p>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-3"></div>
                      <div className="col-lg-6">
                      <h4 className="text-center text-primary">Case Desciption</h4>
                    <p className="text-center inputborder">{loadedcases.casedescription}</p>
                      </div>
                      <div className="col-lg-3"></div>
                    
                    </div> */}
									<hr />
									<h4 className="text-center text-primary">Attachments</h4>
									<div className="mx-auto d-block">
										{loadedcases.attachments !== null ? (
											<div className="row">
												{loadedcases.attachments.split(",").map((fileName, index) => {
													return (
														<div className="col-lg-4">
															{imageExtensions.includes(getFileExtension(fileName)) && (
																<img
																	src={S3_URL + fileName}
																	alt={fileName}
																	className="dropzoneimage img-fluid"
																/>
															)}
															{fileExtensions.includes(getFileExtension(fileName)) && (
																// eslint-disable-next-line jsx-a11y/iframe-has-title

																<div className="iframe-container">
																	<iframe
																		// key={index}
																		src={S3_URL + fileName}
																		className="dropzoneimage responsive-iframe"
																	/>
																</div>
															)}
														</div>
													);
												})}
											</div>
										) : (
											<div>No Attachments</div>
										)}
									</div>
									{/* end of not in table */}
									<hr />
									<h3 className="text-center text-primary">Updates</h3>

									<div className="row">
										<div className="col-lg-11"></div>
										<div className="col-lg-1">
											<Link
												className="btn btn-primary btn-sm"
												to={{
													pathname: "/addactions",
													search: `?caseid=${getsinglecase.caseid}`,
												}}
											>
												Add Case Update
											</Link>
										</div>
									</div>
									{/* payments */}
									<table className="table table-striped table-bordered table-responsive">
										<thead>
											<tr className="table-primary">
												<th>Update Type:</th>
												<th>Update Description Type:</th>
												<th>Update Date</th>
												<th>Attachments</th>
											</tr>
										</thead>
										<tbody>
											{loadedactions.map((option: CaseUpdate) => (
												<tr key={option.actionid} className="table-primary">
													<td>{option.actiontype}</td>
													<td>{option.actiondescription}</td>
													<td>{DateConverter(option.actiondate)}</td>
													<td>
														<div className="">
															{option.attachments !== null ? (
																<div className="row">
																	{option.attachments.split(",").map((fileName, index) => {
																		return (
																			<div className="col-lg-6">
																				{imageExtensions.includes(getFileExtension(fileName)) && (
																					<img
																						src={S3_URL + fileName}
																						alt={fileName}
																						className="dropzoneimage img-fluid"
																					/>
																				)}
																				{fileExtensions.includes(getFileExtension(fileName)) && (
																					// eslint-disable-next-line jsx-a11y/iframe-has-title

																					<div className="iframe-container">
																						<iframe
																							// key={index}
																							src={S3_URL + fileName}
																							className="dropzoneimage responsive-iframe"
																						/>
																					</div>
																				)}
																			</div>
																		);
																	})}
																</div>
															) : (
																<div>No Attachments</div>
															)}
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
									<hr />
									<h3 className="text-center text-primary">Payments</h3>
									<hr />
									<div className="row">
										<div className="col-lg-11"></div>
										<div className="col-lg-1">
											<Link
												className="btn btn-primary btn-sm"
												to={{
													pathname: "/addpayments",
													search: `?caseid=${getsinglecase.caseid}`,
												}}
											>
												Add Payment
											</Link>
										</div>
									</div>

									<div>
										{/* payments */}
										<table className="table table-striped table-bordered table-responsive">
											<thead>
												<tr className="table-primary">
													<th>Amount:</th>
													<th>Payement Type:</th>
													<th>Paid By</th>

													<th>Paid On</th>
													<th>Attachments</th>
												</tr>
											</thead>
											<tbody>
												{loadedpayments.map((option: CasePayment) => (
													<tr key={option.paymentid} className="table-primary">
														<td>{option.amount}</td>
														<td>
															{option.paymenttype === 1 ? (
																<p className="text-success">Paid In</p>
															) : (
																<p className="text-danger">Paid out</p>
															)}
														</td>
														<td>{option.paidby}</td>
														<td>{DateConverter(option.payingdate)}</td>
														<td>
															<div className="">
																{option.attachments !== null ? (
																	<div className="row">
																		{option.attachments.split(",").map((fileName) => {
																			return (
																				<div className="col-lg-6">
																					{imageExtensions.includes(getFileExtension(fileName)) && (
																						<img
																							src={S3_URL + fileName}
																							alt={fileName}
																							className="dropzoneimage img-fluid"
																						/>
																					)}
																					{fileExtensions.includes(getFileExtension(fileName)) && (
																						// eslint-disable-next-line jsx-a11y/iframe-has-title

																						<div className="iframe-container">
																							<iframe
																								// key={index}
																								src={S3_URL + fileName}
																								className="dropzoneimage responsive-iframe"
																							/>
																						</div>
																					)}
																				</div>
																			);
																		})}
																	</div>
																) : (
																	<div>No Attachments</div>
																)}
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</section>
					</div>
					<div className="col-lg-1"></div>

					{/* <div className="align-centre">
              <Link className="btn btn-success" to={"/cashoffice"}>
                Back To Invoices
              </Link>
            </div> */}
				</div>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading Case, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default CaseDetail;
