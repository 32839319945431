import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";
import { useState } from "react";
import { Input } from "../ui/input";
import Pagination from "./Pagination";

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	rowClick?: (id: number) => void;
	rowId?: string;
	searchColumn?: string;
}

const LawFirmTable = <TData, TValue>({
	columns,
	data,
	rowClick,
	rowId,
	searchColumn,
}: DataTableProps<TData, TValue>) => {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
	const [rowSelection, setRowSelection] = useState({});
	const defaultColumnSize = 150;

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	});

	return (
		<>
			{searchColumn ? (
				<div className="flex items-center pb-2 justify-end space-x-2">
					<Input
						placeholder={`Search by ${searchColumn}...`}
						value={(table.getColumn(searchColumn)?.getFilterValue() as string) ?? ""}
						onChange={(event) =>
							table.getColumn(searchColumn)?.setFilterValue(event.target.value)
						}
						className="max-w-sm"
					/>
				</div>
			) : null}
			<div className="border-b">
				<Table>
					<TableHeader className="bg-zinc-100">
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead
											key={header.id}
											style={{
												maxWidth: header.column.columnDef.maxSize,
												width:
													header.column.columnDef.size === defaultColumnSize
														? "auto"
														: header.column.columnDef.size,
											}}
										>
											{header.isPlaceholder
												? null
												: flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									className={rowClick ? "cursor-pointer" : ""}
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
									onClick={() => {
										if (rowClick && rowId) rowClick(parseInt(row.getValue(rowId)));
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell
											key={cell.id}
											style={{ maxWidth: cell.column.columnDef.maxSize }}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24 text-center">
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="pt-4 pb-2">
				<Pagination table={table} />
			</div>
		</>
	);
};

export default LawFirmTable;
