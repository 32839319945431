import { ISODateFormat } from "@/constants";
import { Case } from "@/context/Case/CaseState";
import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

const GETConfig = (accessToken: string) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};
	return {
		headers: headers,
	};
};

const POSTConfig = (accessToken: string) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "multipart/form-data",
	};
	return {
		headers: headers,
	};
};

export const getAllCases = async (accessToken: string): Promise<Case[]> => {
	const res = await axios.get(
		`${server_url}case/getallcases`,
		GETConfig(accessToken)
	);
	return res.data;
};

export const getSingleCase = async (
	accessToken: string,
	caseid: number
): Promise<Case> => {
	const res = await axios.get(
		`${server_url}case/getsinglecase/${caseid}`,
		GETConfig(accessToken)
	);
	return res.data[0];
};

export type ClaimType = {
	claimtypeid: number;
	claimtype: string;
};

export const getClaimTypes = async (
	accessToken: string
): Promise<ClaimType[]> => {
	const res = await axios.get(
		`${server_url}case/getclaimtypes`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type LawFirm = {
	lawfirmid: number;
	lawfirm: string;
	lawfirmemail: string;
};

export const getLawFirms = async (accessToken: string): Promise<LawFirm[]> => {
	const res = await axios.get(
		`${server_url}case/getlawfirms`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type PaymentType = {
	paymenttypeid: number;
	paymenttype: string;
};

export const getPaymentTypes = async (
	accessToken: string
): Promise<PaymentType[]> => {
	const res = await axios.get(
		`${server_url}case/getpaymenttypes`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type CreateCaseParams = {
	issuer: number;
	lawfirm: number;
	claimtype: number;
	caseno: string;
	complainant: string;
	casedescription: string;
	defendingadvocate: string;
	filingdate: string;
	amountsuedfor?: number;
	amountfinancecanoffer?: number;
	amounlegalwillpay?: number;
	files: File[];
};

export const createCase = async (
	accessToken: string,
	data: CreateCaseParams
): Promise<{ message: string }> => {
	const formData = new FormData();

	formData.append("defendingadvocate", data.defendingadvocate);
	formData.append("caseno", data.caseno);
	formData.append("casedescription", data.casedescription);
	formData.append("lawfirm", data.lawfirm.toString());
	formData.append("claimtype", data.claimtype.toString());
	formData.append("complainant", data.complainant);
	formData.append("filingdate", data.filingdate);
	formData.append("issuer", data.issuer.toString());
	data.amountsuedfor &&
		formData.append("amountsuedfor", data.amountsuedfor.toString());
	data.amountfinancecanoffer &&
		formData.append(
			"amountfinancecanoffer",
			data.amountfinancecanoffer.toString()
		);
	data.amounlegalwillpay &&
		formData.append("amounlegalwillpay", data.amounlegalwillpay.toString());
	data.files.forEach((file: File) => {
		formData.append("files", file);
	});

	const res = await axios.post(
		`${server_url}case/addcases`,
		formData,
		POSTConfig(accessToken)
	);

	return res.data;
};

export type SearchAllCasesParams = {
	claimtype?: string;
	complainant?: string;
	filingdate?: string;
	lawfirm?: string;
};

export const defaultSearchList: SearchAllCasesParams = {
	claimtype: "1",
	complainant: "",
	filingdate: "",
	lawfirm: "",
};

export const SearchAllCases = async (
	accessToken: string,
	searchlist: SearchAllCasesParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.post(
		`${server_url}case/searchallcases`,
		searchlist,
		config
	);

	return res.data;
};

export type EventType = {
	actiontypeid: number;
	actiontype: string;
};

export const getEventTypes = async (
	accessToken: string
): Promise<EventType[]> => {
	const res = await axios.get(
		`${server_url}case/getactiontypes`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type Action = {
	actionid: number;
	caseid: number;
	caseno: string;
	createdon: string;
	createdat: string;
	actiondescription: string;
	attachments: null | string;
	username: string;
	actiontype: string;
	actiondate: string;
	nextactiondate: string | null;
	nextactiontype: string | null;
};

export const getCaseActions = async (
	accessToken: string,
	caseid: number
): Promise<Action[]> => {
	const res = await axios.get(
		`${server_url}case/getactions/${caseid}`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type AddCaseActionParams = {
	caseid: number;
	actiontype: number;
	actiondescription: string;
	files: File[];
	actiondate: string;
	nextactiontype: number;
	nextactiondate: string;
};

export const addCaseAction = async (
	accessToken: string,
	data: AddCaseActionParams
) => {
	const formData = new FormData();

	formData.append("caseid", data.caseid.toString());
	formData.append("actiontype", data.actiontype.toString());
	formData.append("actiondescription", data.actiondescription);
	formData.append("actiondate", data.actiondate);
	formData.append("nextactiontype", data.nextactiontype.toString());
	formData.append("nextactiondate", data.nextactiondate);
	data.files.forEach((file: any) => {
		formData.append("files", file);
	});

	const res = await axios.post(
		`${server_url}case/addactions`,
		formData,
		POSTConfig(accessToken)
	);
	return res.data;
};

export type Payment = {
	paymentid: number;
	caseno: string;
	attachments: null | string;
	amount: number;
	paymentdescription: string;
	createdon: string;
	createdat: string;
	payingdate: string;
	paymenttype: string;
	username: string;
	paidby: string;
	caseid: number;
};

export const getCasePayments = async (
	accessToken: string,
	caseid: number
): Promise<Payment[]> => {
	const res = await axios.get(
		`${server_url}case/getpayments/${caseid}`,
		GETConfig(accessToken)
	);
	return res.data;
};

export type AddCasePaymentParams = {
	caseid: number;
	paymentdescription: string;
	amount: number;
	paymenttype: number;
	paidby: string;
	paidto?: string;
	legalfirmpaidto?: number;
	payingdate: string;
	files: File[];
};

export const addCasePayment = async (
	accessToken: string,
	data: AddCasePaymentParams
) => {
	const formData = new FormData();

	formData.append("caseid", data.caseid.toString());
	formData.append("paymentdescription", data.paymentdescription);
	formData.append("amount", data.amount.toString());
	formData.append("paymenttype", data.paymenttype.toString());
	formData.append("paidby", data.paidby);
	formData.append("payingdate", data.payingdate);
	data.paidto && formData.append("paidto", data.paidto);
	data.legalfirmpaidto &&
		formData.append("legalfirmpaidto", data.legalfirmpaidto.toString());
	data.files.forEach((file: any) => {
		formData.append("files", file);
	});

	const res = await axios.post(
		`${server_url}case/addpayments`,
		formData,
		POSTConfig(accessToken)
	);
	return res.data;
};

export type UpcomingEvent = {
	actionid: number;
	actiondate: number;
	caseid: number;
	caseno: string;
	casedescription: string;
	lawfirm: string;
	actiontype: string;
	previousactiondescription: string;
	previousactiontype: string;
	claimtype: string;
	petitioner: string;
	defendingadvocate: string;
};

export const getUpcomingEvents = async (
	accessToken: string
): Promise<UpcomingEvent[]> => {
	const res = await axios.get(
		`${server_url}case/getupcomingevents`,
		GETConfig(accessToken)
	);
	return res.data;
};
