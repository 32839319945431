import { z } from "zod";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { acceptedFiles, ISODateFormat, STALE_TIME } from "@/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	createCase,
	getClaimTypes,
	getLawFirms,
	CreateCaseParams,
} from "@/services/Case.service";
import { UserContext } from "@/context/User/UserContext";
import { toast } from "sonner";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { cn } from "@/lib/util";
import { format } from "date-fns/format";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";

const formSchema = z.object({
	caseno: z.string(),
	issuer: z.number(),
	complainant: z.string(),
	claimtype: z.number(),
	lawfirm: z.number(),
	defendingadvocate: z.string(),
	filingdate: z.date(),
	casedescription: z.string().min(10),
	amountsuedfor: z.number().optional(),
	amountfinancecanoffer: z.number().optional(),
	amounlegalwillpay: z.number().optional(),
	attachment: z.array(z.instanceof(File)).min(0),
});

const CreateCase = () => {
	const user = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
	const [sueing, setSueing] = useState(true);

	const queryClient = useQueryClient();

	const claimTypes = useQuery({
		queryKey: ["claim_types"],
		queryFn: () => getClaimTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const lawFirms = useQuery({
		queryKey: ["law_firms"],
		queryFn: () => getLawFirms(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: 3000,
	});

	const caseCreate = useMutation({
		mutationKey: ["invoice_create"],
		mutationFn: (tableData: CreateCaseParams) =>
			createCase(user?.accessToken!, tableData),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["cases_all"],
				exact: true,
			});
			setOpen(false);
			form.reset();
			toast.success("Case Added");
			caseCreate.reset();
			setUploadedFiles([]);
		},
		onError: (err) => {
			toast.error("Error!", { description: "Error creating case, try again" });
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			issuer: 0,
			casedescription: "",
			attachment: [],
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const tableData: CreateCaseParams = {
			issuer: data.issuer,
			caseno: data.caseno,
			complainant: data.complainant,
			claimtype: data.claimtype,
			lawfirm: data.lawfirm,
			defendingadvocate: data.defendingadvocate,
			filingdate: ISODateFormat(data.filingdate),
			casedescription: data.casedescription,
			amountsuedfor: data.amountsuedfor,
			amountfinancecanoffer: data.amountfinancecanoffer,
			amounlegalwillpay: data.amounlegalwillpay,
			files: data.attachment,
		};
		caseCreate.mutate(tableData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const disabled = () => {
		if (caseCreate.isPending) return true;
		if (caseCreate.isSuccess) return true;
		return false;
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">Add Case</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-3xl">
				<DialogHeader>
					<DialogTitle className="text-left text-3xl font-bold">
						Add Case
					</DialogTitle>
				</DialogHeader>
				{claimTypes.isLoading ? (
					<>Loading Case</>
				) : claimTypes.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<h3 className="font-bold text-xl mb-2">NMG Role</h3>
							<FormField
								name="issuer"
								render={({ field }) => (
									<FormItem className="space-y-3 mb-4">
										<FormControl>
											<RadioGroup
												onValueChange={(e) => {
													field.onChange(parseFloat(e));
													if (parseFloat(e) === 1) {
														setSueing(false);
													} else {
														setSueing(true);
													}
												}}
												disabled={disabled()}
												defaultValue={field.value.toString()}
												className="flex flex-col md:flex-row gap-4 justify-around"
											>
												<FormItem className="flex items-center space-y-0 flex-1">
													<FormLabel className="border rounded-md px-4 py-4 font-medium flex items-center space-x-2 has-[:checked]:border-primary has-[:checked]:bg-primary/10 has-[:checked]:text-primary w-full group cursor-pointer">
														<FormControl className="peer hidden">
															<RadioGroupItem value="0" />
														</FormControl>
														<span className="h-4 w-4 inline-block rounded-full border-2 peer-aria-checked:border-primary peer-aria-checked:border-4" />
														<span className="peer-aria-checked:font-semibold">
															Nation is Plaintiff
														</span>
													</FormLabel>
												</FormItem>

												<FormItem className="flex items-center space-y-0 flex-1">
													<FormLabel className="border rounded-md px-4 py-4 font-medium flex items-center space-x-2 has-[:checked]:border-primary has-[:checked]:bg-primary/10 has-[:checked]:text-primary w-full group cursor-pointer">
														<FormControl className="peer hidden">
															<RadioGroupItem value="1" />
														</FormControl>
														<span className="h-4 w-4 inline-block rounded-full border-2 peer-aria-checked:border-primary peer-aria-checked:border-4" />
														<span className="peer-aria-checked:font-semibold">
															Nation is Defendent
														</span>
													</FormLabel>
												</FormItem>
											</RadioGroup>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<h3 className="font-bold text-xl mb-2">Case Details</h3>
							<div className="flex gap-4">
								<FormField
									name="caseno"
									render={({ field }) => (
										<FormItem className="flex-1 mb-2">
											<FormLabel>Case Number</FormLabel>
											<FormControl>
												<Input type="text" {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									name="claimtype"
									render={({ field }) => (
										<FormItem className="mb-2 flex-1">
											<FormLabel>Case Type</FormLabel>
											<FormControl>
												<Select
													onValueChange={(e) => {
														field.onChange(parseFloat(e));
													}}
													disabled={disabled()}
												>
													<SelectTrigger>
														<SelectValue placeholder="Select an option" />
													</SelectTrigger>
													<SelectContent>
														{claimTypes.data.map((item) => (
															<SelectItem value={item.claimtypeid.toString()}>
																{item.claimtype}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex gap-4">
								<FormField
									name="complainant"
									render={({ field }) => (
										<FormItem className="mb-2 flex-1">
											<FormLabel>Plaintiff/Petitioner</FormLabel>
											<FormControl>
												<Input type="text" {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									name="defendingadvocate"
									render={({ field }) => (
										<FormItem className="mb-2 flex-1">
											<FormLabel>Plaintiff/Petitioner Advocate Name</FormLabel>
											<FormControl>
												<Input type="text" {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<div className="flex gap-4">
								<FormField
									control={form.control}
									name="filingdate"
									render={({ field }) => (
										<FormItem className="flex-1 flex flex-col justify-center">
											<FormLabel>Filling Date</FormLabel>
											<Popover>
												<PopoverTrigger asChild>
													<FormControl>
														<Button
															variant={"outline"}
															className={cn(
																"pl-3 text-left font-normal",
																!field.value && "text-muted-foreground"
															)}
														>
															{field.value ? (
																format(field.value, "PPP")
															) : (
																<span>Pick a date</span>
															)}
															<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
														</Button>
													</FormControl>
												</PopoverTrigger>
												<PopoverContent className="w-auto p-0" align="start">
													<Calendar
														mode="single"
														selected={field.value}
														onSelect={field.onChange}
														disabled={disabled()}
														initialFocus
													/>
												</PopoverContent>
											</Popover>
											<FormMessage />
										</FormItem>
									)}
								/>
								{lawFirms.isLoading ? (
									<>Loading Law Firms</>
								) : lawFirms.data ? (
									<FormField
										name="lawfirm"
										render={({ field }) => (
											<FormItem className="mb-2 flex-1">
												<FormLabel>Law Firm in Charge</FormLabel>
												<FormControl>
													<Select
														onValueChange={(e) => {
															field.onChange(parseFloat(e));
														}}
														disabled={disabled()}
													>
														<SelectTrigger>
															<SelectValue placeholder="Select an option" />
														</SelectTrigger>
														<SelectContent>
															{lawFirms.data.map((item) => (
																<SelectItem value={item.lawfirmid.toString()}>
																	{item.lawfirm}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								) : null}
							</div>
							<FormField
								name="casedescription"
								render={({ field }) => (
									<FormItem className="mb-4">
										<FormLabel>Case Summary</FormLabel>
										<FormControl>
											<Textarea {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage className="font-light" />
									</FormItem>
								)}
							/>

							{/* <FormField
								control={form.control}
								name="nextcourtdate"
								render={({ field }) => (
									<FormItem className="flex-1 flex flex-col justify-center">
										<FormLabel>Next Court Date</FormLabel>
										<Popover>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant={"outline"}
														className={cn(
															"pl-3 text-left font-normal",
															!field.value && "text-muted-foreground"
														)}
													>
														{field.value ? (
															format(field.value, "PPP")
														) : (
															<span>Pick a date</span>
														)}
														<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className="w-auto p-0" align="start">
												<Calendar
													mode="single"
													selected={field.value}
													onSelect={field.onChange}
													disabled={disabled()}
													initialFocus
												/>
											</PopoverContent>
										</Popover>
										<FormMessage />
									</FormItem>
								)}
							/> */}
							<>
								<h3 className="font-bold text-xl mb-2 mt-6">Settlement/Damages</h3>
								<div className="flex flex-col md:flex-row gap-4">
									<FormField
										name="amountsuedfor"
										render={({ field }) => (
											<FormItem className="mb-2 flex-1">
												<FormLabel>Amount Being Sued For</FormLabel>
												<FormControl>
													<Input
														type="number"
														placeholder="Enter amount"
														onChange={(e) => field.onChange(e.target.valueAsNumber)}
														disabled={disabled()}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									{!sueing ? (
										<>
											<FormField
												name="amountfinancecanoffer"
												render={({ field }) => (
													<FormItem className="mb-2 flex-1">
														<FormLabel>Amount Finance Can Offer</FormLabel>
														<FormControl>
															<Input
																type="number"
																placeholder="Enter amount"
																onChange={(e) => field.onChange(e.target.valueAsNumber)}
																disabled={disabled()}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
											<FormField
												name="amounlegalwillpay"
												render={({ field }) => (
													<FormItem className="mb-2 flex-1">
														<FormLabel>Amount Legal will Pay</FormLabel>
														<FormControl>
															<Input
																type="number"
																placeholder="Enter amount"
																onChange={(e) => field.onChange(e.target.valueAsNumber)}
																disabled={disabled()}
															/>
														</FormControl>
														<FormMessage />
													</FormItem>
												)}
											/>
										</>
									) : null}
								</div>
							</>
							<FormField
								name="attachment"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Attachments</FormLabel>
										<FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-4 cursor-pointer hover:bg-muted">
											<div className="flex flex-col items-center justify-center gap-2">
												<MdOutlineCloudUpload className="text-4xl" />
												<p>
													<b>Click to upload</b>
												</p>
												<p className="font-light">SVG, PNG, JPG or PDF</p>
											</div>
											<FormControl className="hidden">
												<Input
													type="file"
													multiple
													onChange={(e) => {
														handleFileChange(e);
														// field.onChange(e.target.files);
													}}
													disabled={disabled()}
												/>
											</FormControl>
										</FormLabel>
										<FormMessage />
									</FormItem>
								)}
							/>
							{uploadedFiles.length === 0 ? null : (
								<AttachmentPreview
									files={uploadedFiles}
									deleteFile={removeFile}
									disabled={disabled()}
								/>
							)}
							<Button
								type="submit"
								className="mt-4 w-full"
								disabled={caseCreate.isPending || caseCreate.isSuccess}
							>
								{caseCreate.isPending ? "Creating..." : "Add Case"}
							</Button>
						</form>
					</Form>
				) : claimTypes.error ? (
					<div className="text-red-500">
						There was an error loading invoice types
					</div>
				) : null}
			</DialogContent>
		</Dialog>
	);
};

export default CreateCase;
