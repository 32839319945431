import { z } from "zod";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { acceptedFiles, ISODateFormat, STALE_TIME } from "@/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	addCasePayment,
	AddCasePaymentParams,
	getLawFirms,
	getPaymentTypes,
} from "@/services/Case.service";
import { toast } from "sonner";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { cn } from "@/lib/util";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../ui/calendar";
import { format } from "date-fns/format";
import { FiPlusCircle } from "react-icons/fi";

const formSchema = z.object({
	paymentdescription: z.string(),
	amount: z.number(),
	paymenttype: z.number(),
	paidby: z.string(),
	paidto: z.string().optional(),
	legalfirmpaidto: z.number().optional(),
	payingdate: z.date(),
	attachment: z.array(z.instanceof(File)).min(0),
});

const CreatePayment: React.FC<{ id: number; accessToken: string }> = ({
	id,
	accessToken,
}) => {
	const [open, setOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
	const [isLegalFee, setIsLegalFee] = useState(true);

	const queryClient = useQueryClient();

	const paymentTypes = useQuery({
		queryKey: ["payment_types"],
		queryFn: () => getPaymentTypes(accessToken),
		staleTime: STALE_TIME,
	});

	const lawFirms = useQuery({
		queryKey: ["law_firms"],
		queryFn: () => getLawFirms(accessToken),
		staleTime: 3000,
	});

	const casePaymentCreate = useMutation({
		mutationKey: ["case_payment_create"],
		mutationFn: (tableData: AddCasePaymentParams) =>
			addCasePayment(accessToken, tableData),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["case", "payments", id],
				exact: true,
			});
			setOpen(false);
			form.reset();
			toast.success("Payment Added");
			casePaymentCreate.reset();
			setUploadedFiles([]);
		},
		onError: (err) => {
			toast.error("Error!", { description: "Error creating payment, try again" });
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			attachment: [],
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const tableData: AddCasePaymentParams = {
			caseid: id,
			paymentdescription: data.paymentdescription,
			amount: data.amount,
			paymenttype: data.paymenttype,
			paidby: data.paidby,
			paidto: data.paidto,
			legalfirmpaidto: data.legalfirmpaidto,
			payingdate: ISODateFormat(data.payingdate),
			files: data.attachment,
		};
		casePaymentCreate.mutate(tableData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const disabled = () => {
		if (casePaymentCreate.isPending) return true;
		if (casePaymentCreate.isSuccess) return true;
		return false;
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">
					<FiPlusCircle className="text-lg mr-2" /> Add Payment
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-lg">
				<DialogHeader>
					<DialogTitle className="text-left">Add Payment</DialogTitle>
				</DialogHeader>
				{paymentTypes.isLoading ? (
					<>Loading Payment Types</>
				) : paymentTypes.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<FormField
								name="paymenttype"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Payment Type</FormLabel>
										<FormControl>
											<Select
												onValueChange={(e) => {
													field.onChange(parseFloat(e));
													if (parseFloat(e) === 1) {
														setIsLegalFee(true);
													} else {
														setIsLegalFee(false);
													}
												}}
												disabled={disabled()}
											>
												<SelectTrigger>
													<SelectValue placeholder="Select an option" />
												</SelectTrigger>
												<SelectContent>
													{paymentTypes.data.map((item) => (
														<SelectItem value={item.paymenttypeid.toString()}>
															{item.paymenttype}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="paidby"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Paid By</FormLabel>
										<FormControl>
											<Input type="text" {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							{isLegalFee ? (
								lawFirms.isLoading ? (
									<>Loading Law Firms</>
								) : lawFirms.data ? (
									<FormField
										name="legalfirmpaidto"
										render={({ field }) => (
											<FormItem className="mb-2 flex-1">
												<FormLabel>Choose Law Firm</FormLabel>
												<FormControl>
													<Select
														onValueChange={(e) => {
															field.onChange(parseFloat(e));
														}}
														disabled={disabled()}
													>
														<SelectTrigger>
															<SelectValue placeholder="Select an option" />
														</SelectTrigger>
														<SelectContent>
															{lawFirms.data.map((item) => (
																<SelectItem value={item.lawfirmid.toString()}>
																	{item.lawfirm}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								) : null
							) : (
								<FormField
									name="paidto"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Paid To</FormLabel>
											<FormControl>
												<Input type="text" {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							)}
							<FormField
								control={form.control}
								name="payingdate"
								render={({ field }) => (
									<FormItem className="flex-1 flex flex-col justify-center mb-2">
										<FormLabel>Payment Date</FormLabel>
										<Popover>
											<PopoverTrigger asChild>
												<FormControl>
													<Button
														variant={"outline"}
														className={cn(
															"pl-3 text-left font-normal",
															!field.value && "text-muted-foreground"
														)}
													>
														{field.value ? (
															format(field.value, "PPP")
														) : (
															<span>Pick a date</span>
														)}
														<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
													</Button>
												</FormControl>
											</PopoverTrigger>
											<PopoverContent className="w-auto p-0" align="start">
												<Calendar
													mode="single"
													selected={field.value}
													onSelect={field.onChange}
													disabled={disabled()}
													initialFocus
												/>
											</PopoverContent>
										</Popover>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="paymentdescription"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Payment Description</FormLabel>
										<FormControl>
											<Textarea {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage className="font-light" />
									</FormItem>
								)}
							/>
							<FormField
								name="amount"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Amount</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder="Enter Amount"
												onChange={(e) => field.onChange(e.target.valueAsNumber)}
												disabled={disabled()}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="attachment"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Attachments</FormLabel>
										<FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-4 cursor-pointer hover:bg-muted">
											<div className="flex flex-col items-center justify-center gap-2">
												<MdOutlineCloudUpload className="text-4xl" />
												<p>
													<b>Click to upload</b>
												</p>
												<p className="font-light">SVG, PNG, JPG or PDF</p>
											</div>
											<FormControl className="hidden">
												<Input
													type="file"
													multiple
													onChange={(e) => {
														handleFileChange(e);
														// field.onChange(e.target.files);
													}}
													disabled={disabled()}
												/>
											</FormControl>
										</FormLabel>
										<FormMessage />
									</FormItem>
								)}
							/>
							{uploadedFiles.length === 0 ? null : (
								<AttachmentPreview
									files={uploadedFiles}
									deleteFile={removeFile}
									disabled={disabled()}
								/>
							)}
							<Button
								type="submit"
								className="mt-4 w-full"
								disabled={casePaymentCreate.isPending || casePaymentCreate.isSuccess}
							>
								{casePaymentCreate.isPending ? "Creating..." : "Add Payment"}
							</Button>
						</form>
					</Form>
				) : paymentTypes.error ? (
					<div className="text-red-500">
						There was an error loading payment types
					</div>
				) : null}
				{/* <DialogFooter>
					<Button type="submit">Save changes</Button>
				</DialogFooter> */}
			</DialogContent>
		</Dialog>
	);
};

export default CreatePayment;
