import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export type AddPaymentTypeParams = {
	paymenttype: string;
};

export const addPaymentType = async (
	accessToken: string,
	params: AddPaymentTypeParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const response = await axios.post(
		`${server_url}case/addpaymenttype`,
		params,
		config
	);

	return response.data;
};
