import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import {
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";
import { useContext, useEffect, useState } from "react";
import { Input } from "../ui/input";
import ViewOptions from "./ViewOptions";
import { Case } from "@/context/Case/CaseState";
import Pagination from "./Pagination";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { cn } from "@/lib/util";
import { LucideSettings2 } from "lucide-react";
import { Separator } from "../ui/separator";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useQuery } from "@tanstack/react-query";
import { getClaimTypes } from "@/services/Case.service";
import { UserContext } from "@/context/User/UserContext";
import { STALE_TIME } from "@/constants";

interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[];
	data: TData[];
	rowClick?: (id: number) => void;
	rowId?: string;
	searchColumn?: string;
}

const CaseTable = <TData, TValue>({
	columns,
	data,
	rowClick,
	rowId,
	searchColumn,
}: DataTableProps<TData, TValue>) => {
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
	const [rowSelection, setRowSelection] = useState({});

	const user = useContext(UserContext);

	const defaultColumnSize = 150;

	const table = useReactTable({
		data,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	});

	const claimTypes = useQuery({
		queryKey: ["claim_types"],
		queryFn: () => getClaimTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	return (
		<>
			{/* <div className="flex items-center pb-2 justify-end space-x-2">
				{searchColumn ? (
					<Input
						placeholder={`Search by ${searchColumn}...`}
						value={(table.getColumn(searchColumn)?.getFilterValue() as string) ?? ""}
						onChange={(event) =>
							table.getColumn(searchColumn)?.setFilterValue(event.target.value)
						}
						className="max-w-sm"
					/>
				) : null}

				<Popover>
					<PopoverTrigger asChild>
						<Button variant="outline" className="flex px-3">
							<LucideSettings2 className="mr-2 h-4 w-4" />
							Filter
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-[250px] p-0" align="end">
						<div>
							<div className="p-2 text-sm font-medium">Filter</div>
						</div>
						<Separator />
						{claimTypes.isLoading ? (
							<p>Loading...</p>
						) : claimTypes.data ? (
							<div className="px-2 py-3">
								<div className="text-sm mb-3">Claim Type</div>
								<Select>
									<SelectTrigger className="text-sm">
										<SelectValue placeholder="Select an option" />
									</SelectTrigger>
									<SelectContent className="text-xs">
										{claimTypes.data.map((item) => (
											<SelectItem value={item.claimtypeid.toString()} className="text-sm">
												{item.claimtype}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
						) : null}
					</PopoverContent>
				</Popover>
				<ViewOptions table={table} />
			</div> */}
			<div>
				<Table>
					<TableHeader className="bg-zinc-100">
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead
											key={header.id}
											style={{
												maxWidth: header.column.columnDef.maxSize,
												width:
													header.column.columnDef.size == defaultColumnSize
														? "auto"
														: header.column.columnDef.size,
											}}
										>
											{header.isPlaceholder
												? null
												: flexRender(header.column.columnDef.header, header.getContext())}
										</TableHead>
									);
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									className={rowClick ? "cursor-pointer" : ""}
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
									onClick={() => {
										if (rowClick && rowId) rowClick(parseInt(row.getValue(rowId)));
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell
											key={cell.id}
											style={{ maxWidth: cell.column.columnDef.maxSize }}
										>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24 text-center">
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="pt-4 pb-2">
				<Pagination table={table} />
			</div>
		</>
	);
};

export default CaseTable;
