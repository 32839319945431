import { getCasePayments, Payment } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import React, { useState } from "react";
import DataTable from "./table/DataTable";
import ColumnHeader from "./table/ColumnHeader";
import DisplayPayment from "./dialog/DisplayPayment";
import { Button } from "./ui/button";
import { convertDateToText } from "@/constants";
import CreatePayment from "./dialog/CreatePayment";

const CasePayments: React.FC<{ id: number; accessToken: string }> = ({
	id,
	accessToken,
}) => {
	const [showPaymentDetails, setShowPaymentDetails] = useState(false);
	const [currentPayment, setCurrentPayment] = useState<Payment | null>(null);
	const casePayments = useQuery({
		queryKey: ["case", "payments", id],
		queryFn: () => getCasePayments(accessToken, id),
	});

	const displayPayment = (payment: Payment) => {
		setShowPaymentDetails(true);
		setCurrentPayment(payment);
	};
	return (
		<>
			<DisplayPayment
				open={showPaymentDetails}
				setOpen={setShowPaymentDetails}
				payment={currentPayment}
			/>
			<h2 className="uppercase text-gray-700 font-bold tracking-wider">
				Case Payments
			</h2>
			<hr className="mb-4" />
			<div className="pb-4 flex justify-end">
				<CreatePayment id={id} accessToken={accessToken} />
			</div>
			{casePayments.isLoading ? (
				<div>Loading...</div>
			) : casePayments.data ? (
				<DataTable columns={Columns(displayPayment)} data={casePayments.data} />
			) : (
				<div>No actions found.</div>
			)}
		</>
	);
};

export default CasePayments;

const Columns = (
	displayEvent: (payment: Payment) => void
): ColumnDef<Payment>[] => {
	return [
		{
			accessorKey: "paymentid",
			header: ({ column }) => <ColumnHeader column={column} title="Payment ID" />,
			cell: ({ row }) => (
				<Button variant="link" onClick={() => displayEvent(row.original)}>
					{row.getValue("paymentid")}
				</Button>
			),
		},
		{
			accessorKey: "paymentdescription",
			header: ({ column }) => (
				<ColumnHeader column={column} title="Payment Description" />
			),
			cell: ({ row }) => {
				const payment = row.original;
				return (
					<div className="text-sm flex gap-1 items-center">
						<span className="text-xs rounded-full px-1.5 py-0.5 font-medium text-nowrap bg-primary/10 text-primary">
							{payment.paymenttype}
						</span>
						<p>
							{payment.paymentdescription.length > 40
								? payment.paymentdescription.slice(0, 40) + "..."
								: payment.paymentdescription}
						</p>
					</div>
				);
			},
		},
		{
			accessorKey: "amount",
			header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
		},
		{
			accessorKey: "payingdate",
			header: ({ column }) => <ColumnHeader column={column} title="Paying Date" />,
			cell: ({ row }) => convertDateToText(row.getValue("payingdate")),
		},
		{
			accessorKey: "paidby",
			header: ({ column }) => <ColumnHeader column={column} title="Paid By" />,
		},
		{
			accessorKey: "username",
			header: ({ column }) => <ColumnHeader column={column} title="Created By" />,
		},
		{
			accessorKey: "createdon",
			header: ({ column }) => <ColumnHeader column={column} title="Created On" />,
			cell: ({ row }) => convertDateToText(row.getValue("createdon")),
		},
		{
			accessorKey: "attachments",
			header: ({ column }) => <ColumnHeader column={column} title="Attachments" />,
		},
	];
};
