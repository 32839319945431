import { z } from "zod";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import { act, useContext, useState } from "react";
import { acceptedFiles, ISODateFormat, STALE_TIME } from "@/constants";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	AddCaseActionParams,
	addCaseAction,
	getEventTypes,
} from "@/services/Case.service";
import { UserContext } from "@/context/User/UserContext";
import { toast } from "sonner";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import { FiPlusCircle } from "react-icons/fi";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/util";
import { format } from "date-fns/format";
import { Calendar } from "../ui/calendar";

const formSchema = z.object({
	actiontype: z.number(),
	actiondescription: z.string().min(10),
	attachment: z.array(z.instanceof(File)).min(0),
	actiondate: z.date(),
	nextactiontype: z.number(),
	nextactiondate: z.date(),
});

const CreateUpdate: React.FC<{ id: number }> = ({ id }) => {
	const user = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);

	const queryClient = useQueryClient();

	const eventTypes = useQuery({
		queryKey: ["event_types"],
		queryFn: () => getEventTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const updateCreate = useMutation({
		mutationKey: ["invoice_create"],
		mutationFn: (tableData: AddCaseActionParams) =>
			addCaseAction(user?.accessToken!, tableData),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["case", "events", id],
				exact: true,
			});
			setOpen(false);
			form.reset();
			toast.success("Action Added");
			updateCreate.reset();
			setUploadedFiles([]);
		},
		onError: (err) => {
			toast.error("Error!", { description: "Error adding action, try again" });
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			attachment: [],
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const tableData: AddCaseActionParams = {
			caseid: id,
			actiontype: data.actiontype,
			actiondescription: data.actiondescription,
			files: data.attachment,
			actiondate: ISODateFormat(data.actiondate),
			nextactiontype: data.nextactiontype,
			nextactiondate: ISODateFormat(data.nextactiondate),
		};
		updateCreate.mutate(tableData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const disabled = () => {
		if (updateCreate.isPending) return true;
		if (updateCreate.isSuccess) return true;
		return false;
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">
					<FiPlusCircle className="text-lg mr-2" /> Add Event
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle className="text-left">Add Event Here</DialogTitle>
				</DialogHeader>
				{eventTypes.isLoading ? (
					<>Loading Event</>
				) : eventTypes.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-2 md:mb-0">
								<FormField
									name="actiontype"
									render={({ field }) => (
										<FormItem className="mb-2 flex-1">
											<FormLabel>Event Type</FormLabel>
											<FormControl>
												<Select
													onValueChange={(e) => {
														field.onChange(parseFloat(e));
													}}
													disabled={disabled()}
												>
													<SelectTrigger>
														<SelectValue placeholder="Select an option" />
													</SelectTrigger>
													<SelectContent>
														{eventTypes.data.map((item) => (
															<SelectItem value={item.actiontypeid.toString()}>
																{item.actiontype}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="actiondate"
									render={({ field }) => (
										<FormItem className="flex-1 flex flex-col justify-center">
											<FormLabel>Event Date</FormLabel>
											<Popover>
												<PopoverTrigger asChild>
													<FormControl>
														<Button
															variant={"outline"}
															className={cn(
																"pl-3 text-left font-normal",
																!field.value && "text-muted-foreground"
															)}
														>
															{field.value ? (
																format(field.value, "PPP")
															) : (
																<span>Pick a date</span>
															)}
															<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
														</Button>
													</FormControl>
												</PopoverTrigger>
												<PopoverContent className="w-auto p-0" align="start">
													<Calendar
														mode="single"
														selected={field.value}
														onSelect={field.onChange}
														disabled={disabled()}
														initialFocus
													/>
												</PopoverContent>
											</Popover>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<FormField
								name="actiondescription"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Event Description</FormLabel>
										<FormControl>
											<Textarea {...field} disabled={disabled()} />
										</FormControl>
										<FormMessage className="font-light" />
									</FormItem>
								)}
							/>
							<div className="flex flex-col md:flex-row gap-2 md:gap-4 mb-2 md:mb-0">
								<FormField
									name="nextactiontype"
									render={({ field }) => (
										<FormItem className="mb-2 flex-1">
											<FormLabel>Next Event Type</FormLabel>
											<FormControl>
												<Select
													onValueChange={(e) => {
														field.onChange(parseFloat(e));
													}}
													disabled={disabled()}
												>
													<SelectTrigger>
														<SelectValue placeholder="Select an option" />
													</SelectTrigger>
													<SelectContent>
														{eventTypes.data.map((item) => (
															<SelectItem value={item.actiontypeid.toString()}>
																{item.actiontype}
															</SelectItem>
														))}
													</SelectContent>
												</Select>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								<FormField
									control={form.control}
									name="nextactiondate"
									render={({ field }) => (
										<FormItem className="flex-1 flex flex-col justify-center">
											<FormLabel>Next Action Date</FormLabel>
											<Popover>
												<PopoverTrigger asChild>
													<FormControl>
														<Button
															variant={"outline"}
															className={cn(
																"pl-3 text-left font-normal",
																!field.value && "text-muted-foreground"
															)}
														>
															{field.value ? (
																format(field.value, "PPP")
															) : (
																<span>Pick a date</span>
															)}
															<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
														</Button>
													</FormControl>
												</PopoverTrigger>
												<PopoverContent className="w-auto p-0" align="start">
													<Calendar
														mode="single"
														selected={field.value}
														onSelect={field.onChange}
														disabled={disabled()}
														initialFocus
													/>
												</PopoverContent>
											</Popover>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
							<FormField
								name="attachment"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Attachments</FormLabel>
										<FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-4 cursor-pointer hover:bg-muted">
											<div className="flex flex-col items-center justify-center gap-2">
												<MdOutlineCloudUpload className="text-4xl" />
												<p>
													<b>Click to upload</b>
												</p>
												<p className="font-light">SVG, PNG, JPG or PDF</p>
											</div>
											<FormControl className="hidden">
												<Input
													type="file"
													multiple
													onChange={(e) => {
														handleFileChange(e);
														// field.onChange(e.target.files);
													}}
													disabled={disabled()}
												/>
											</FormControl>
										</FormLabel>
										<FormMessage />
									</FormItem>
								)}
							/>
							{uploadedFiles.length === 0 ? null : (
								<AttachmentPreview
									files={uploadedFiles}
									deleteFile={removeFile}
									disabled={disabled()}
								/>
							)}
							<Button
								type="submit"
								className="mt-4 w-full"
								disabled={updateCreate.isPending || updateCreate.isSuccess}
							>
								{updateCreate.isPending ? "Creating..." : "Add Event"}
							</Button>
						</form>
					</Form>
				) : eventTypes.error ? (
					<div className="text-red-500">
						There was an error loading invoice types
					</div>
				) : null}
			</DialogContent>
		</Dialog>
	);
};

export default CreateUpdate;
