import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export type AddActionTypeParams = {
	actiontype: string;
};

export const addActionType = async (
	accessToken: string,
	params: AddActionTypeParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const response = await axios.post(
		`${server_url}case/addactiontypes`,
		params,
		config
	);

	return response.data;
};
