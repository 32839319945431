import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export type AddLawFirmParams = {
	lawfirm: string;
	lawfirmemail: string;
	phoneno: string;
	legalrepresentative: string;
	legalrepresentativephone: string;
	legalrepresentativeemail: string;
};

export const addLawFirm = async (
	accessToken: string,
	params: AddLawFirmParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const response = await axios.post(
		`${server_url}case/addlawfirms`,
		params,
		config
	);

	return response.data;
};
