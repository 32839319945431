// TODO remove unused types

export const GET_PROFILE='GET_PROFILE'
export const GET_PROFILE_ERROR='GET_PROFILE_ERROR'

export const GET_PROFILE_PHOTO='GET_PROFILE_PHOTO'
export const GET_PROFILE_PHOTO_ERROR='GET_PROFILE_PHOTO_ERROR'

export const GET_USER_TOKEN = 'GET_USER_TOKEN'
export const GET_USER_TOKEN_ERROR = 'GET_USER_TOKEN_ERROR'

export const GET_INVOICE_TYPES = 'GET_INVOICE_TYPES'
export const GET_INVOICE_TYPES_ERROR = 'GET_INVOICE_TYPES_ERROR'

export const CREATE_INVOICE = 'CREATE_INVOICE'
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR'

export const INVOICE_TRACKING = 'INVOICE_TRACKING'
export const INVOICE_TRACKING_ERROR = 'INVOICE_TRACKING_ERROR'

export const CREATE_PER_DIEM = 'CREATE_PER_DIEM'
export const CREATE_PER_DIEM_ERROR = 'CREATE_PER_DIEM_ERROR'

export const CREATE_LOCAL_PER_DIEM = 'CREATE_LOCAL_PER_DIEM'
export const CREATE_LOCAL_PER_DIEM_ERROR = 'CREATE_LOCAL_PER_DIEM_ERROR'

export const GET_MANAGER = 'GET_MANAGER'
export const GET_MANAGER_ERROR = 'GET_MANAGER_ERROR'

export const INVOICE_TRACKING_DETAIL = 'INVOICE_TRACKING_DETAIL'
export const INVOICE_TRACKING_DETAIL_ERROR = 'INVOICE_TRACKING_DETAIL_ERROR'

export const GET_MANAGER_APPROVE_LIST = 'GET_MANAGER_APPROVE_LIST'
export const GET_MANAGER_APPROVE_LIST_ERROR = 'GET_MANAGER_APPROVE_LIST_ERROR'

export const GET_FINANCE_APPROVE_LIST = 'GET_FINANCE_APPROVE_LIST'
export const GET_FINANCE_APPROVE_LIST_ERROR = 'GET_FINANCE_APPROVE_LIST_ERROR'

export const MANAGER_APPROVE = 'MANAGER_APPROVE'
export const MANAGER_APPROVE_ERROR = 'MANAGER_APPROVE_ERROR'

export const CLEAR_INVOICE_ERRORS = 'CLEAR_INVOICE_ERRORS'

export const FINANCE_APPROVE = 'FINANCE_APPROVE'
export const FINANCE_APPROVE_ERROR = 'FINANCE_APPROVE_ERROR'

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR'

export const MANAGER_REJECT = 'MANAGER_REJECT'
export const MANAGER_REJECT_ERROR = 'MANAGER_REJECT_ERROR'

export const FINANCE_REJECT = 'FINANCE_REJECT'
export const FINANCE_REJECT_ERROR = 'FINANCE_REJECT_ERROR'

export const GET_GL_ACCOUNTS = 'GET_GL_ACCOUNTS'
export const GET_GL_ACCOUNTS_ERROR = 'GET_GL_ACCOUNTS_ERROR'

export const GET_COST_CENTRES = 'GET_COST_CENTRES'
export const GET_COST_CENTRES_ERROR = 'GET_COST_CENTRES_ERROR'

export const INVOICE_MASTER_TRACKING = 'INVOICE_MASTER_TRACKING'
export const INVOICE_MASTER_TRACKING_ERROR = 'INVOICE_MASTER_TRACKING_ERROR'

export const INVOICE_MASTER_FINANCE_TRACKING = 'INVOICE_MASTER_FINANCE_TRACKING'
export const INVOICE_MASTER_FINANCE_TRACKING_ERROR = 'INVOICE_MASTER_FINANCE_TRACKING_ERROR'

export const MANAGER_DIRECT_REPORTS = 'MANAGER_DIRECT_REPORTS'
export const MANAGER_DIRECT_REPORTS_ERROR = 'MANAGER_DIRECT_REPORTS_ERROR'

export const GET_LOCAL_CITIES = 'GET_LOCAL_CITIES'
export const GET_LOCAL_CITIES_ERROR = 'GET_LOCAL_CITIES_ERROR'

export const GET_JOB_GRADES = 'GET_JOB_GRADES'
export const GET_JOB_GRADES_ERROR = 'GET_JOB_GRADES_ERROR'

export const GET_TYPES_OF_PER_DIEMS = 'GET_TYPES_OF_PER_DIEMS'
export const GET_TYPES_OF_PER_DIEMS_ERROR = 'GET_TYPES_OF_PER_DIEMS_ERROR'

export const GET_INVOICE_MASTER = 'GET_INVOICE_MASTER'
export const GET_INVOICE_MASTER_ERROR = 'GET_INVOICE_MASTER_ERROR'

export const GET_INVOICE_MASTER_SINGLE = 'GET_INVOICE_MASTER_SINGLE'
export const GET_INVOICE_MASTER_SINGLE_ERROR = 'GET_INVOICE_MASTER_SINGLE_ERROR'

export const GET_ALL_USERS = 'GET_ALL_USERS'
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR'

export const GET_ALL_INVOICES_APPROVED = "GET_ALL_INVOICES_APPROVED"
export const GET_ALL_INVOICES_APPROVED_ERROR = "GET_ALL_INVOICES_APPROVED_ERROR"

export const GET_ALL_INVOICES_REJECTED = "GET_ALL_INVOICES_REJECTED"
export const GET_ALL_INVOICES_REJECTED_ERROR = "GET_ALL_INVOICES_REJECTED_ERROR"

export const GET_ALL_INVOICES_PROGRESS = "GET_ALL_INVOICES_PROGRESS"
export const GET_ALL_INVOICES_PROGRESS_ERROR = "GET_ALL_INVOICES_PROGRESS_ERROR"

export const UPDATE_AWS_FILES = "UPDATE_AWS_FILES"
export const UPDATE_AWS_FILES_ERROR = "UPDATE_AWS_FILES_ERROR"

export const UPDATE_USER_DEPARTMENT = "UPDATE_USER_DEPARTMENT"
export const UPDATE_USER_DEPARTMENT_ERROR = "UPDATE_USER_DEPARTMENT_ERROR"

export const GET_USER_BY_EMAIL = "GET_USER_BY_EMAIL"
export const GET_USER_BY_EMAIL_ERROR = "GET_USER_BY_EMAIL_ERROR"

export const CLEAR_AUTH_ERRORS = "CLEAR_AUTH_ERRORS"

export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS"
export const GET_ALL_DEPARTMENTS_ERROR = "GET_ALL_DEPARTMENTS_ERROR"

export const GET_SINGLE_USER_DETAILS = "GET_SINGLE_USER_DETAILS"
export const GET_SINGLE_USER_DETAILS_ERROR = "GET_SINGLE_USER_DETAILS_ERROR"

export const ME_DIRECT_REPORTS = "ME_DIRECT_REPORTS"
export const ME_DIRECT_REPORTS_ERROR = "ME_DIRECT_REPORTS_ERROR"

export const CASH_OFFICE_COLLECTION = "CASH_OFFICE_COLLECTION"
export const CASH_OFFICE_COLLECTION_ERROR = "CASH_OFFICE_COLLECTION_ERROR"

export const GET_ALL_INVOICES_APPROVED_NOT_PAID = "GET_ALL_INVOICES_APPROVED_NOT_PAID"
export const GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR = "GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR"

export const GET_ALL_INVOICES_PAID = "GET_ALL_INVOICES_PAID"
export const GET_ALL_INVOICES_PAID_ERROR = "GET_ALL_INVOICES_PAID_ERROR"

export const GET_USER_DETAIL_BY_ID = "GET_USER_DETAIL_BY_ID"
export const GET_USER_DETAIL_BY_ID_ERROR = "GET_USER_DETAIL_BY_ID_ERROR"

export const GET_USER_TYPES = "GET_USER_TYPES"
export const GET_USER_TYPES_ERROR = "GET_USER_TYPES_ERROR"

export const UPDATE_USER_BY_ID = "UPDATE_USER_BY_ID"
export const UPDATE_USER_BY_ID_ERROR = "UPDATE_USER_BY_ID_ERROR"



// TODO remain with the constants below only

export const GET_USER_CHECK = 'GET_USER_CHECK'
export const GET_USER_CHECK_ERROR = 'GET_USER_CHECK_ERROR'

export const GET_LAW_FIRMS = "GET_LAW_FIRMS"
export const GET_LAW_FIRMS_ERROR = "GET_LAW_FIRMS_ERROR"

export const CLEAR_CASE_ERRORS = 'CLEAR_CASE_ERRORS'

export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

export const GET_CLAIM_TYPES = 'GET_CLAIM_TYPES'
export const GET_CLAIM_TYPES_ERROR = 'GET_CLAIM_TYPES_ERROR'

export const ADD_CASE = 'ADD_CASE'
export const ADD_CASE_ERROR = 'ADD_CASE_ERROR'

export const GET_ALL_CASES = 'GET_ALL_CASES'
export const GET_ALL_CASES_ERROR = 'GET_ALL_CASES_ERROR'

export const GET_SINGLE_CASES = 'GET_SINGLE_CASES'
export const GET_SINGLE_CASES_ERROR = 'GET_SINGLE_CASES_ERROR'


export const GET_ACTION_TYPES = 'GET_ACTION_TYPES'
export const GET_ACTION_TYPES_ERROR = 'GET_ACTION_TYPES_ERROR'

export const GET_PAYMENT_TYPES = 'GET_PAYMENT_TYPES'
export const GET_PAYMENT_TYPES_ERROR = 'GET_PAYMENT_TYPES_ERROR'

export const ADD_ACTIONS = 'ADD_ACTIONS'
export const ADD_ACTIONS_ERROR = 'ADD_ACTIONS_ERROR'

export const ADD_PAYMENTS = 'ADD_PAYMENTS'
export const ADD_PAYMENTS_ERROR = 'ADD_PAYMENTS_ERROR'


export const GET_ACTIONS = 'GET_ACTIONS'
export const GET_ACTIONS_ERROR = 'GET_ACTIONS_ERROR'

export const GET_PAYMENTS = 'GET_PAYMENTS'
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR'


export const GET_ALL_PAYMENTS = 'GET_ALL_PAYMENTS'
export const GET_ALL_PAYMENTS_ERROR = 'GET_ALL_PAYMENTS_ERROR'


export const GET_ALL_ACTIONS = 'GET_ALL_ACTIONS'
export const GET_ALL_ACTIONS_ERROR = 'GET_ALL_ACTIONS_ERROR'


export const SEARCH_ALL_CASES = 'SEARCH_ALL_CASES'
export const SEARCH_ALL_CASES_ERROR = 'SEARCH_ALL_CASES_ERROR'