import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Navbar from "./layout/Navbar";
import ClaimTypes from "./pages/ClaimTypes";
import LawFirms from "./pages/LawFirms";
import Payments from "./pages/Payments";
// import AddCase from "./pages/AddCase";
import CaseDetail from "./pages/CaseDetail";
import AddActions from "./pages/AddActions";
import AddPayments from "./pages/AddPayments";
import CasesDashboard from "@/pages/CasesDashboard";
import Header from "./Header";
import CaseDetails from "@/pages/CaseDetails";
import Sidebar from "./Sidebar";
import ActionTypes from "./pages/ActionTypes";
import MobileSidebar from "./MobileSidebar";
import PaymentTypes from "@/pages/PaymentTypes";

const RouteComponent = () => {
	return (
		<main className="bg-backgroundLight">
			<Router>
				<Fragment>
					{/* <Routes>
                    <Route path="/" component={Login} />
                    </Routes>    */}
					{/* <Navbar /> */}
					{/* <Header /> */}
					<Sidebar />
					<div className="lg:ml-[300px] grow">
						<div className="block lg:hidden bg-white px-4 py-2 border-b border-zinc-200 shadow-sm sticky top-0 z-10">
							<MobileSidebar />
						</div>
						<div className="py-8 px-4">
							<Routes>
								<Fragment>
									<Route path="/" element={<CasesDashboard />}></Route>
									<Route path="/old" element={<Dashboard />}></Route>
									<Route path="/claim-types" element={<ClaimTypes />}></Route>
									<Route path="/event-types" element={<ActionTypes />}></Route>
									<Route path="/payment-types" element={<PaymentTypes />}></Route>
									<Route path="/law-firms" element={<LawFirms />}></Route>
									<Route path="/payments" element={<Payments />}></Route>
									{/* <Route path="/addcase" element={<AddCase/>}></Route> */}
									<Route path="/casedetail" element={<CaseDetail />}></Route>
									<Route path="/case/:id" element={<CaseDetails />}></Route>
									<Route path="/addactions" element={<AddActions />}></Route>
									<Route path="/addpayments" element={<AddPayments />}></Route>
								</Fragment>
							</Routes>
						</div>
					</div>
				</Fragment>
			</Router>
		</main>
	);
};

export default RouteComponent;
