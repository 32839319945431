import { useMsal } from "@azure/msal-react";
import { BsMicrosoft } from "react-icons/bs";
import logo_no from "@/media/nation.png";
import { loginRequest } from "@/authConfig";
import { Button } from "@/components/ui/button";

const Login = () => {
	const { instance } = useMsal();

	const handleLoginRedirect = () => {
		instance
			.loginRedirect({
				...loginRequest,
				prompt: "create",
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<div className="bg-slate-100 h-screen flex items-center justify-center">
			<div className="bg-white text-center p-6 border rounded-md w-full mx-4 sm:max-w-lg">
				<div className="flex flex-col items-center justify-center gap-2 pb-8">
					<div className="aspect-square w-16">
						<img src={logo_no} alt="" className="w-full h-full" />
					</div>
					<h1 className="text-4xl font-bold">Case Management</h1>
				</div>
				<div className="flex flex-col items-center justify-center pb-8">
					<h4 className="font-semibold text-2xl">Welcome Back</h4>
					<p className="text-zinc-600 font-light">Sign in with your work email</p>
				</div>
				<Button className="w-full" onClick={() => handleLoginRedirect()}>
					<BsMicrosoft className="mr-2" /> Sign In with Microsoft
				</Button>
			</div>
		</div>
	);
};

export default Login;
