/* eslint-disable import/no-anonymous-default-export */
import {
	ADD_ACTIONS,
	ADD_ACTIONS_ERROR,
	ADD_CASE,
	ADD_CASE_ERROR,
	ADD_PAYMENTS,
	ADD_PAYMENTS_ERROR,
	CLEAR_CASE_ERRORS,
	GET_ACTIONS,
	GET_ACTIONS_ERROR,
	GET_ACTION_TYPES,
	GET_ACTION_TYPES_ERROR,
	GET_ALL_CASES,
	GET_ALL_CASES_ERROR,
	GET_ALL_PAYMENTS,
	GET_ALL_PAYMENTS_ERROR,
	GET_CLAIM_TYPES,
	GET_CLAIM_TYPES_ERROR,
	GET_LAW_FIRMS,
	GET_LAW_FIRMS_ERROR,
	GET_PAYMENTS,
	GET_PAYMENTS_ERROR,
	GET_PAYMENT_TYPES,
	GET_PAYMENT_TYPES_ERROR,
	GET_SINGLE_CASES,
	GET_SINGLE_CASES_ERROR,
	SEARCH_ALL_CASES,
	SEARCH_ALL_CASES_ERROR,
} from "../types";

const CaseReducer = (state: any, action: any) => {
	switch (action.type) {
		case CLEAR_CASE_ERRORS:
			return {
				...state,
				caseserror: null,
				addcases: null,
				addpayments: null,
				addactions: null,
				searchallcases: null,
			};

		case SEARCH_ALL_CASES:
			return {
				...state,
				loading: false,
				searchallcases: action.payload,
			};

		case ADD_ACTIONS:
			return {
				...state,
				loading: false,
				addactions: action.payload,
			};

		case ADD_PAYMENTS:
			return {
				...state,
				loading: false,
				addpayments: action.payload,
			};

		case GET_ACTION_TYPES:
			return {
				...state,
				loading: false,
				getactiontypes: action.payload,
			};
		case GET_PAYMENT_TYPES:
			return {
				...state,
				loading: false,
				getpaymenttypes: action.payload,
			};

		case GET_ALL_CASES:
			return {
				...state,
				loading: false,
				getallcases: action.payload,
			};

		case GET_SINGLE_CASES:
			return {
				...state,
				loading: false,
				getsinglecase: action.payload,
			};

		case GET_LAW_FIRMS:
			return {
				...state,
				loading: false,
				getlawfirms: action.payload,
			};

		case GET_CLAIM_TYPES:
			return {
				...state,
				loading: false,
				getclaimtypes: action.payload,
			};

		case ADD_CASE:
			return {
				...state,
				loading: false,
				addcases: action.payload,
			};

		case GET_ACTIONS:
			return {
				...state,
				loading: false,
				getactions: action.payload,
			};

		case GET_PAYMENTS:
			return {
				...state,
				loading: false,
				getpayments: action.payload,
			};

		case GET_ALL_PAYMENTS:
			return {
				...state,
				loading: false,
				getallpayments: action.payload,
			};

		case GET_LAW_FIRMS_ERROR:
		case GET_CLAIM_TYPES_ERROR:
		case ADD_CASE_ERROR:
		case GET_ALL_CASES_ERROR:
		case GET_SINGLE_CASES_ERROR:
		case GET_ACTION_TYPES_ERROR:
		case GET_PAYMENT_TYPES_ERROR:
		case ADD_PAYMENTS_ERROR:
		case ADD_ACTIONS_ERROR:
		case GET_PAYMENTS_ERROR:
		case GET_ACTIONS_ERROR:
		case GET_ALL_PAYMENTS_ERROR:
		case SEARCH_ALL_CASES_ERROR:
			return {
				...state,
				loading: false,
				caseerror: action.payload,
			};

		default:
			return state;
	}
};

export default CaseReducer;
