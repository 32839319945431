/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext, useEffect } from "react";
import CaseContext from "../../context/Case/CaseContext";
import AlertContext from "../../context/alert/AlertContext";
import { useDropzone } from "react-dropzone";
import { Typeahead } from "react-bootstrap-typeahead";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";

import {
	getFileExtension,
	imageExtensions,
	fileExtensions,
	acceptedFiles,
	ISODateFormat,
} from "../../constants";

const AddCase = () => {
	const casecontext = useContext(CaseContext);
	const alertContext = useContext(AlertContext);

	const {
		getlawfirms,
		getLawFirms,
		getclaimtypes,
		getClaimTypes,
		AddCases,
		addcases,
	} = casecontext;

	//   hook form
	const { register, handleSubmit, reset } = useForm();
	const [startDate, setStartDate] = useState(new Date());
	const [lawfirmState, setLawfirm] = useState<any[]>([]);
	const [claimtypeState, setClaimType] = useState<any[]>([]);
	const [files, setFiles] = useState<any[]>([]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		getLawFirms();
		getClaimTypes();
		if (addcases) {
			if (alertContext) alertContext.setAlert(addcases.message, "success");
			setFiles([]);
			setLawfirm([]);
			setClaimType([]);
			setStartDate(new Date());
			reset();
			setVisible(false);
		}
		// eslint-disable-next-line
	}, [reset, addcases]);

	const MAX_FILE_SIZE = 2097152;

	// Handle file drop
	const handleFileChange = (acceptedFiles: any, rejectedFiles: any) => {
		if (rejectedFiles.length > 0) {
			// Handle rejected files (size or type restrictions)
			rejectedFiles.forEach((file: any) => {
				if (alertContext) alertContext.setAlert(file.errors[0].message, "danger");
			});
		} else {
			// Handle acceptedFiles (upload, store, etc.)
			setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		}
	};

	const handleFileDrop = (index: number) => {
		setFiles((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};

	// React Dropzone configuration
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleFileChange,
		accept: acceptedFiles.join(",") as any,
		multiple: true,
		maxSize: MAX_FILE_SIZE,
	});

	let loadedlawfirms = [];
	if (getlawfirms) {
		loadedlawfirms = getlawfirms;
	} else {
		loadedlawfirms = [];
	}

	let loadedclaimtypes = [];
	if (getclaimtypes) {
		loadedclaimtypes = getclaimtypes;
	} else {
		loadedclaimtypes = [];
	}

	const onSubmit = (data: any) => {
		let lawfirm = parseInt(lawfirmState[0].lawfirmid);
		let claimtype = parseInt(claimtypeState[0].claimtypeid);
		let caseno = data.caseno;
		let complainant = data.complainant;
		let casedescription = data.casedescription;
		let defendingadvocate = data.defendingadvocate;
		let filingdate = ISODateFormat(startDate);

		AddCases({
			files,
			lawfirm,
			claimtype,
			caseno,
			complainant,
			casedescription,
			defendingadvocate,
			filingdate,
		});
		setVisible(true);
	};
	return (
		<div>
			<div className="">
				<Alert />
				<div className="row">
					<div className="col-lg-3"></div>
					<div className="col-lg-6">
						{visible ? (
							<div className="align-centre">
								<h5 className="text-center">Adding Case...</h5>
								<Spinner />
							</div>
						) : (
							<div></div>
						)}
					</div>
					<div className="col-lg-3"></div>
				</div>
				{/* <div className="col-lg-2"></div> */}
				<div className="p-1">
					<h3 className="text-center text-primary">Add Case</h3>
					<hr />

					{/* form */}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-4">
								<div className="form-group p-1">
									<label htmlFor="">Case Number</label>
									<input
										type="text"
										className="form-control inputbordersm"
										{...register("caseno", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="form-group p-1">
									<label htmlFor="">Plaintiff/Petitioner</label>
									<input
										type="text"
										className="form-control inputbordersm"
										{...register("complainant", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="col-lg-6">
								<div className="form-group p-1">
									<label htmlFor="">Case Type</label>
									{loadedclaimtypes.length !== 0 && (
										<Typeahead
											clearButton
											id="basic-typeahead-single"
											labelKey="claimtype"
											defaultSelected={loadedclaimtypes.slice(0, 1)}
											onChange={setClaimType}
											options={loadedclaimtypes}
											placeholder="Select Claim Type"
											selected={claimtypeState}
											size="lg"
											inputProps={{ required: true }}
										/>
									)}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="form-group p-1">
									<label htmlFor="">Law Firm In Charge</label>
									{loadedlawfirms.length !== 0 && (
										<Typeahead
											id="basic-typeahead-double"
											clearButton
											defaultSelected={loadedlawfirms.slice(0, 1)}
											labelKey="lawfirm"
											onChange={setLawfirm}
											options={loadedlawfirms}
											placeholder="Select Law Firm"
											selected={lawfirmState}
											// className="inputborder"
											size="lg"
											inputProps={{ required: true }}
										/>
									)}
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-2"></div>
							<div className="col-lg-8">
								<div className="form-group p-1">
									<label htmlFor="">Plaintiff/Petitioner Advocate Name</label>
									<input
										type="text"
										className="form-control inputbordersm"
										{...register("defendingadvocate", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
						{/* filing date */}
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<div className="form-group p-1">
									<label htmlFor="">Filing Date</label>
									<DatePicker
										className="form-control"
										selected={startDate}
										onChange={(date: Date) => setStartDate(date)}
										inline
									/>
								</div>
							</div>
							<div className="col-lg-4"></div>
						</div>

						{/* end of filing date */}
						<div className="row">
							<div className="col-lg-1"></div>
							<div className="col-lg-10">
								<div className="form-group p-3">
									<label htmlFor="description">Case Summary:</label>
									<textarea
										id="casedescription"
										className="form-control inputborder"
										rows={4}
										required
										{...register("casedescription", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="col-lg-1"></div>
						</div>
						<div className="row">
							<div className="col-lg-4"></div>

							<div className="col-lg-4"></div>
						</div>
						{/* files */}
						<div className="form-group p-1">
							<label htmlFor="file">Files:</label>
							{files.length > 0 && (
								<div className="dropzone">
									<div className="row">
										{files.map((file, index) => (
											<div className="col-lg-6">
												<div className="dropzoneimage">
													{imageExtensions.includes(getFileExtension(file.name)) && (
														<img
															src={URL.createObjectURL(file)}
															alt="Uploaded file"
															className="img-fluid"
															// style={{ maxWidth: "20em" }}
														/>
													)}
													{fileExtensions.includes(getFileExtension(file.name)) && (
														// eslint-disable-next-line jsx-a11y/iframe-has-title
														<div className="iframe-container">
															<iframe
																key={index}
																src={URL.createObjectURL(file)}
																// width={800}
																// height={500}
																className=" responsive-iframe"
															/>
														</div>
													)}
													<div>
														<button
															type="button"
															className="btn btn-outline-danger remove-button"
															onClick={() => handleFileDrop(index)}
														>
															Remove File
														</button>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							<div {...getRootProps()} className="dropzone">
								Click to upload a file
								<input {...getInputProps()} className="form-control" multiple />
							</div>
						</div>
						{/* end of files */}
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<button className="btn btn-primary col-12 mx-auto" type="submit">
									Add Case
								</button>
							</div>
							<div className="col-lg-4"></div>
						</div>
					</form>
					{/* end of form */}
				</div>
				{/* <div className="col-lg-2"></div> */}
			</div>
		</div>
	);
};

export default AddCase;
