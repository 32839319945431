/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect } from "react";
import CaseContext from "../../context/Case/CaseContext";
// import { useLocation } from "react-router-dom";
import Spinner from "../layout/Spinner";
// import AlertContext from "../../context/alert/AlertContext";
// import { Link } from "react-router-dom";

import {
	S3_URL,
	DateConverter,
	getFileExtension,
	imageExtensions,
	fileExtensions,
	// TimeConverter,
} from "../../constants";
import { CasePayment } from "@/context/Case/CaseState";

const Payments = () => {
	let casecontext = useContext(CaseContext);
	// const alertContext = useContext(AlertContext);

	const { getallpayments, getAllPayments } = casecontext;

	useEffect(() => {
		// loadToken()
		getAllPayments();

		// eslint-disable-next-line
	}, []);

	let loadedallpayments = [];
	if (getallpayments) {
		loadedallpayments = getallpayments;
	} else {
		loadedallpayments = [];
	}

	return (
		<div>
			<div className="row">
				<div className="col-lg-2 nah shadow-lg p-3">
					<h3 className="text-center">Filter Payments</h3>
				</div>
				<div className="col-lg-1"></div>
				<div className="col-lg-9 nah shadow-lg p-3">
					<h3 className="text-center">All Payments</h3>
					<div>
						{getallpayments ? (
							<table className="table table-striped table-bordered table-responsive">
								<thead>
									<tr className="table-primary">
										<th>Amount:</th>
										<th>Payement Type:</th>
										<th>Paid By</th>

										<th>Paid On</th>
										<th>Attachments</th>
									</tr>
								</thead>
								<tbody>
									{loadedallpayments.map((option: CasePayment) => (
										<tr key={option.paymentid} className="table-primary">
											<td>{option.amount}</td>
											<td>
												{option.paymenttype === 1 ? (
													<p className="text-success">Paid In</p>
												) : (
													<p className="text-danger">Paid out</p>
												)}
											</td>
											<td>{option.paidby}</td>
											<td>{DateConverter(option.payingdate)}</td>
											<td>
												<div className="">
													{option.attachments !== null ? (
														<div className="row">
															{option.attachments.split(",").map((fileName, index) => {
																return (
																	<div className="col-lg-6">
																		{imageExtensions.includes(getFileExtension(fileName)) && (
																			<img
																				src={S3_URL + fileName}
																				alt={fileName}
																				className="dropzoneimage img-fluid"
																			/>
																		)}
																		{fileExtensions.includes(getFileExtension(fileName)) && (
																			// eslint-disable-next-line jsx-a11y/iframe-has-title

																			<div className="iframe-container">
																				<iframe
																					// key={index}
																					src={S3_URL + fileName}
																					className="dropzoneimage responsive-iframe"
																				/>
																			</div>
																		)}
																	</div>
																);
															})}
														</div>
													) : (
														<div>No Attachments</div>
													)}
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						) : (
							<div className="align-centre">
								<h5 className="text-center">Loading Payments, please wait...</h5>
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payments;
