import React from "react";
import "../src/styles/App.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-responsive-modal/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthState from "./context/Auth/AuthState";
import CaseState from "./context/Case/CaseState";
import AlertState from "./context/alert/AlertState";
import RouteComponent from "./components/RouteComponent";
import Login from "./components/pages/Login";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-responsive-modal/styles.css";
import UserState from "./context/User/UserState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
	return (
		<div className="App">
			<AuthenticatedTemplate>
				<UserState>
					<AuthState>
						<CaseState>
							<AlertState>
								<RouteComponent />
							</AlertState>
						</CaseState>
					</AuthState>
				</UserState>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</div>
	);
};

export default function App() {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			<MainContent />
			<Toaster richColors position="top-center" />
		</QueryClientProvider>
	);
}
