import { getUpcomingEvents, UpcomingEvent } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Skeleton } from "./ui/skeleton";
import DataTable from "./table/DataTable";
import { ColumnDef } from "@tanstack/react-table";
import ColumnHeader from "./table/ColumnHeader";
import { NavLink } from "react-router-dom";
import { Button } from "./ui/button";
import { convertDateToText } from "@/constants";
import DisplayUpcomingEvent from "./dialog/DisplayUpcomingEvent";

const UpcomingEvents: React.FC<{ accessToken: string }> = ({ accessToken }) => {
	const [showEventDetails, setShowEventDetails] = useState(false);
	const [currentEvent, setCurrentEvent] = useState<UpcomingEvent | null>(null);
	const upcomingEvents = useQuery({
		queryKey: ["upcoming_events"],
		queryFn: () => getUpcomingEvents(accessToken),
	});

	const displayEvent = (event: UpcomingEvent) => {
		setShowEventDetails(true);
		setCurrentEvent(event);
	};

	return (
		<div className="mb-6">
			<DisplayUpcomingEvent
				open={showEventDetails}
				setOpen={setShowEventDetails}
				event={currentEvent}
			/>
			<h2 className="uppercase text-gray-700 font-bold tracking-wider">
				Upcoming Events
			</h2>
			<hr className="mb-4" />
			<div className="bg-white pb-4 border rounded-md shadow-sm">
				{upcomingEvents.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 10 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : upcomingEvents.data ? (
					<DataTable data={upcomingEvents.data} columns={Columns(displayEvent)} />
				) : (
					<p>No Upcoming Events</p>
				)}
			</div>
		</div>
	);
};

export default UpcomingEvents;

const Columns = (
	displayEvent: (event: UpcomingEvent) => void
): ColumnDef<UpcomingEvent>[] => {
	return [
		{
			accessorKey: "actionid",
			header: ({ column }) => <ColumnHeader column={column} title="Event ID" />,
			cell: ({ row }) => (
				<Button
					variant="link"
					className="px-2 py-1"
					onClick={() => displayEvent(row.original)}
				>
					{row.getValue("actionid")}
				</Button>
			),
		},
		{
			accessorKey: "actiontype",
			header: ({ column }) => <ColumnHeader column={column} title="Action Type" />,
		},
		{
			accessorKey: "actiondate",
			header: ({ column }) => <ColumnHeader column={column} title="Action Date" />,
			cell: ({ row }) => convertDateToText(row.getValue("actiondate")),
		},
		{
			accessorKey: "petitioner",
			header: ({ column }) => <ColumnHeader column={column} title="Petitioner" />,
		},
		{
			accessorKey: "lawfirm",
			header: ({ column }) => <ColumnHeader column={column} title="Law Firm" />,
		},
		{
			accessorKey: "caseno",
			header: ({ column }) => <ColumnHeader column={column} title="Case No" />,
			cell: ({ row }) => (
				<div className="flex items-center">
					<NavLink to={`/case/${row.original.caseid}`}>
						<Button variant="link" className="px-2 py-1">
							{row.getValue("caseno")}
						</Button>
					</NavLink>
				</div>
			),
		},
	];
};
