import Logo from "@/media/nation.png";
import { NavLink } from "react-router-dom";
import { PiSignOutBold } from "react-icons/pi";
import { useMsal } from "@azure/msal-react";
import { RiDashboardFill } from "react-icons/ri";
import {
	BsBuildingsFill,
	BsCheckCircleFill,
	BsCheckSquareFill,
} from "react-icons/bs";
import { cn } from "@/lib/util";
import { FaMoneyBillTransfer } from "react-icons/fa6";

const Sidebar: React.FC<{ closeSidebar?: () => void }> = ({ closeSidebar }) => {
	const { instance, accounts } = useMsal();

	const currentAccount = instance.getAccountByHomeId(accounts[0]?.homeAccountId);

	const redirecturi = process.env.REACT_APP_REDIRECT_URI;

	const handleLogoutRedirect = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: redirecturi,
			account: currentAccount,
		});
		window.location.reload();
	};

	return (
		<div
			className={cn(
				" hidden h-full lg:flex flex-col bg-zinc-100 border-r border-zinc-200",
				closeSidebar ? "flex grow" : "w-[300px] fixed"
			)}
		>
			<div className="flex items-center gap-2 px-4 py-2 border-b border-zinc-300">
				<div className="h-8">
					<img src={Logo} alt="logo" className="h-full w-full" />
				</div>
				<h3 className="text-xl font-bold">Nation</h3>
			</div>
			<div className="flex flex-col gap-4 px-4 py-2 justify-between grow overflow-y-auto">
				<ul className="flex flex-col gap-2">
					<li onClick={closeSidebar}>
						<NavLink to="/" className="sidebar-link">
							<RiDashboardFill className="text-2xl" />
							<span>Dashboard</span>
						</NavLink>
					</li>
					<li onClick={closeSidebar}>
						<NavLink to="/law-firms" className="sidebar-link">
							<BsBuildingsFill className="text-2xl" />
							<span>Law Firms</span>
						</NavLink>
					</li>
					<li onClick={closeSidebar}>
						<NavLink to="/claim-types" className="sidebar-link">
							<BsCheckCircleFill className="text-lg" />
							<span>Case Types</span>
						</NavLink>
					</li>
					<li onClick={closeSidebar}>
						<NavLink to="/event-types" className="sidebar-link">
							<BsCheckSquareFill className="text-lg" />
							<span>Event Types</span>
						</NavLink>
					</li>
					<li onClick={closeSidebar}>
						<NavLink to="/payment-types" className="sidebar-link">
							<FaMoneyBillTransfer className="text-lg" />
							<span>Payment Types</span>
						</NavLink>
					</li>
				</ul>
				<button className="sidebar-link" onClick={handleLogoutRedirect}>
					<PiSignOutBold className="text-2xl" />
					<span>Logout</span>
				</button>
			</div>
		</div>
	);
};

export default Sidebar;
