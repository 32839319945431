import { z } from "zod";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import { UserState } from "@/context/User/UserReducer";
import {
	addClaimType,
	AddClaimTypeParams,
} from "@/services/ClaimTypes.service";

const formSchema = z.object({
	claimtype: z.string(),
});

const CreateClaimType: React.FC<{ user: UserState | null }> = ({ user }) => {
	const [open, setOpen] = useState(false);

	const queryClient = useQueryClient();

	const createClaimType = useMutation({
		mutationKey: ["claimtype_create"],
		mutationFn: (data: AddClaimTypeParams) =>
			addClaimType(user?.accessToken!, data),
		onSuccess: (_, variables) => {
			queryClient.invalidateQueries({
				queryKey: ["claim_types"],
				exact: true,
				refetchType: "all",
			});
			setOpen(false);
			form.reset();
			toast.success("Success", {
				description: `Case type ${variables.claimtype} has been successfully created`,
			});
			createClaimType.reset();
		},
		onError: (err) => {
			toast.error("Error!", { description: "Error adding case type, try again" });
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		console.log(data);
		createClaimType.mutate(data);
	};

	const disabled = () => {
		if (createClaimType.isPending) return true;
		if (createClaimType.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">Add Case Type</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle className="text-left font-bold text-2xl">
						Add Case Type
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<FormField
							name="claimtype"
							render={({ field }) => (
								<FormItem className="flex-1 mb-2">
									<FormLabel>Case Type</FormLabel>
									<FormControl>
										<Input type="text" {...field} disabled={disabled()} />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<Button
							type="submit"
							className="mt-4 w-full"
							disabled={createClaimType.isPending || createClaimType.isSuccess}
						>
							{createClaimType.isPending ? "Creating..." : "Create Case Type"}
						</Button>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default CreateClaimType;
