import { getFileExtension, imageExtensions } from "@/constants";
import { Case } from "@/context/Case/CaseState";
import React, { useState, useEffect } from "react";
import CaseAttachments from "./CaseAttachments";

type Props = {
	attachments: string;
};

const AttachmentDetails: React.FC<Props> = ({ attachments }) => {
	const [images, setImages] = useState<string[]>([]);
	const [files, setFiles] = useState<string[]>([]);

	const prepareAttachments = (attachments: string) => {
		const [im, fl] = attachments.split(",").reduce<[string[], string[]]>(
			([images, files], attachment) => {
				if (imageExtensions.includes(getFileExtension(attachment))) {
					images.push(attachment);
				} else {
					files.push(attachment);
				}
				return [images, files];
			},
			[[], []]
		);
		setImages(im);
		setFiles(fl);
	};

	useEffect(() => {
		if (attachments) prepareAttachments(attachments);
	}, [attachments]);
	return <CaseAttachments images={images} docs={files} />;
};

export default AttachmentDetails;
