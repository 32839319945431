import { Button } from "@/components/ui/button";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "@/components/ui/sheet";
import { convertDateToText, convertToTimeFormat } from "@/constants";
import { Payment } from "@/services/Case.service";
import { BiCalendar, BiTagAlt, BiUser } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import { RiAttachment2 } from "react-icons/ri";
import AttachmentDetails from "../AttachmentDetails";

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	payment: Payment | null;
};

const DisplayPayment: React.FC<Props> = ({ open, setOpen, payment }) => {
	if (!payment) return null;

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="sm:max-w-2xl">
				<SheetHeader>
					<SheetTitle className="text-3xl">
						<span className="text-gray-600 font-bold mr-2">{`#${payment.paymentid}`}</span>
						<span className="font-semibold">
							{convertDateToText(payment.payingdate)}
						</span>
					</SheetTitle>
				</SheetHeader>
				<div className="grid py-4 gap-4">
					<div className="grid grid-cols-4 gap-y-4 gap-x-1">
						<div className="col-span-1 flex gap-6">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiTagAlt className="text-lg mr-2" />
								Event Type
							</span>
						</div>
						<div className="col-span-3">
							<div className="flex-1 w-5/6">
								<span className="text-sm text-green-700 bg-green-100 border border-green-300 rounded-sm font-semibold px-2 py-1 self-start">
									{payment.paymenttype}
								</span>
							</div>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiCalendar className="text-lg mr-2" />
								Created
							</span>
						</div>
						<div className="col-span-3">
							<span>
								{convertDateToText(payment.createdon)}{" "}
								{convertToTimeFormat(payment.createdat)}
							</span>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiUser className="text-lg mr-2" />
								Created By
							</span>
						</div>
						<div className="col-span-3">
							<span>{payment.username}</span>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<CgNotes className="text-lg mr-2" />
								Description
							</span>
						</div>
						<div className="col-span-4">
							<span>{payment.paymentdescription}</span>
						</div>
						{payment.attachments ? (
							<>
								<div className="col-span-1 flex flex-col gap-4">
									<span className="inline-flex items-center font-light text-gray-600">
										<RiAttachment2 className="text-lg mr-2" />
										Attachments
									</span>
								</div>
								<div className="col-span-4">
									<AttachmentDetails attachments={payment.attachments} />
								</div>
							</>
						) : null}
					</div>
				</div>
				<SheetFooter className="sm:justify-start">
					<SheetClose asChild>
						<Button type="submit">Done</Button>
					</SheetClose>
				</SheetFooter>
			</SheetContent>
		</Sheet>
	);
};

export default DisplayPayment;
