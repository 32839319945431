import { useContext } from "react";
import { getLawFirms, LawFirm } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "@/context/User/UserContext";
import { ColumnDef } from "@tanstack/react-table";
import ColumnHeader from "../table/ColumnHeader";
import { NavLink } from "react-router-dom";
import { Button } from "../ui/button";
import LawFirmTable from "../table/LawFirmTable";
import CreateLawFirm from "../dialog/CreateLawFirm";
import { Skeleton } from "../ui/skeleton";

const LawFirms = () => {
	const user = useContext(UserContext);

	const lawFirms = useQuery({
		queryKey: ["law_firms"],
		queryFn: () => getLawFirms(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: 3000,
	});
	return (
		<div>
			<div className="flex justify-between items-center mb-4">
				<div>
					<h1 className="text-3xl font-bold">Law Firms</h1>
				</div>
				<CreateLawFirm user={user} />
			</div>
			<div className="bg-white pb-4 border rounded-md shadow-sm">
				{lawFirms.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 10 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : lawFirms.data ? (
					<LawFirmTable data={lawFirms.data} columns={Columns} />
				) : (
					<p>No data</p>
				)}
			</div>
		</div>
	);
};

export default LawFirms;

const Columns: ColumnDef<LawFirm>[] = [
	{
		accessorKey: "lawfirmid",
		header: ({ column }) => <ColumnHeader column={column} title="Law Firm Id" />,
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/law-firm/${row.original.lawfirmid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("lawfirmid")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "lawfirm",
		header: ({ column }) => <ColumnHeader column={column} title="Law Firm" />,
	},
	{
		accessorKey: "lawfirmemail",
		header: ({ column }) => <ColumnHeader column={column} title="Email" />,
	},
];
