import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export type AddClaimTypeParams = {
	claimtype: string;
};

export const addClaimType = async (
	accessToken: string,
	params: AddClaimTypeParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const response = await axios.post(
		`${server_url}case/addclaimtypes`,
		params,
		config
	);

	return response.data;
};
