/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import CaseContext from "../../context/Case/CaseContext";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import { DateConverter, ISODateFormat } from "../../constants";
import { Typeahead } from "react-bootstrap-typeahead";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import AlertContext from "../../context/alert/AlertContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Alert from "../layout/Alert";
import { Modal } from "react-responsive-modal";
import AddCase from "./AddCase";
import { Case, ClaimType, LawFirm } from "@/context/Case/CaseState";

const Dashboard = () => {
	let casecontext = useContext(CaseContext);
	const alertContext = useContext(AlertContext);
	// let navigate_ = useNavigate();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [representingFirm, setRepresentingFirm] = useState(false);
	const [filterPetioner, setFilterPetioner] = useState(false);
	const [filterFilingDate, setFilterFilingDate] = useState(false);
	const [filterCaseType, setFilterCaseType] = useState(false);
	const [visible, setVisible] = useState(false);
	const [showAll, setShowAll] = useState(true);

	const [startDate, setStartDate] = useState(new Date());
	const [lawfirmStateDash, setLawfirmDash] = useState<LawFirm[]>([]);
	const [claimtypeStateDash, setClaimTypeDash] = useState<ClaimType[]>([]);

	const { register, handleSubmit, reset } = useForm();

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	const {
		getallcases,
		getAllCases,
		addcases,
		getlawfirms,
		getLawFirms,
		getclaimtypes,
		getClaimTypes,
		clearCaseErrors,
		SearchAllCases,
		searchallcases,
	} = casecontext;

	useEffect(() => {
		getLawFirms();
		getClaimTypes();
		getAllCases();
		if (addcases) {
			setModalIsOpen(false);
			clearCaseErrors();
		}

		if (searchallcases) {
			if (searchallcases.length === 0 || searchallcases === null) {
				if (alertContext) alertContext.setAlert("No result found", "danger");
				setVisible(false);
			} else {
				setVisible(false);
				setShowAll(false);
			}
		}

		// eslint-disable-next-line
	}, [addcases, searchallcases, reset]);

	let loadedcases = [];
	if (getallcases) {
		loadedcases = getallcases;
	} else {
		loadedcases = [];
	}

	let loadedlawfirms = [];
	if (getlawfirms) {
		loadedlawfirms = getlawfirms;
	} else {
		loadedlawfirms = [];
	}

	let loadedclaimtypes = [];
	if (getclaimtypes) {
		loadedclaimtypes = getclaimtypes;
	} else {
		loadedclaimtypes = [];
	}

	// toggle funcs
	const toggle_firm = () => {
		setRepresentingFirm(!representingFirm);
	};

	const toggle_petitioner = () => {
		setFilterPetioner(!filterPetioner);
	};

	const toggle_filingdate = () => {
		setFilterFilingDate(!filterFilingDate);
	};

	const toggle_casetype = () => {
		setFilterCaseType(!filterCaseType);
	};
	// end of toggle funcs

	const clearFilters = () => {
		clearCaseErrors();
		reset();
		setRepresentingFirm(false);
		setFilterFilingDate(false);
		setFilterCaseType(false);
		setFilterPetioner(false);
		setClaimTypeDash([]);
		setLawfirmDash([]);
		setShowAll(true);
	};

	const onSubmit = (data: any) => {
		let claimtype = 0;
		let complainant = "";
		let filingdate = "";
		let lawfirm = 0;
		if (filterCaseType === true) {
			claimtype = claimtypeStateDash[0].claimtypeid;
		}

		if (filterPetioner) {
			complainant = data.petitioner;
		}

		if (filterFilingDate) {
			filingdate = ISODateFormat(startDate);
		}
		if (representingFirm) {
			lawfirm = lawfirmStateDash[0].lawfirmid;
		}
		SearchAllCases({ claimtype, complainant, filingdate, lawfirm });
		setVisible(true);
	};
	return (
		<div>
			<div className="row">
				<Alert />
				<div className="col-lg-2 nah shadow-lg p-2">
					{showAll === false && (
						<Button
							color="primary"
							className="mx-auto d-block m-3"
							variant="contained"
							onClick={clearFilters}
						>
							Clear All Filters
						</Button>
					)}

					{visible ? (
						<div className="align-centre">
							<h5 className="text-center">Searching...</h5>
							<Spinner />
						</div>
					) : (
						<div></div>
					)}
					<h3 className="text-center">Filter Cases</h3>
					<h4>
						<u>Filter By:</u>
					</h4>
					<div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group p-3">
								<input
									type="checkbox"
									name="filingdatecheck"
									checked={filterFilingDate}
									onChange={toggle_filingdate}
								/>
								<span>Filing Date</span>
								{filterFilingDate && (
									<div className="form-group m-3">
										{/* <label htmlFor=""></label> */}
										<DatePicker
											className="form-control"
											selected={startDate}
											onChange={(date: Date) => setStartDate(date)}
											inline
											required
										/>
									</div>
								)}
							</div>

							<div className="form-group p-3">
								<input
									type="checkbox"
									name="casetypecheck"
									checked={filterCaseType}
									onChange={toggle_casetype}
								/>
								<span>Case Type</span>

								{filterCaseType && (
									<div className="form-group m-3">
										{/* <label htmlFor=""></label> */}
										{loadedclaimtypes.length !== 0 && (
											<Typeahead
												clearButton
												id="basic-typeahead-single"
												labelKey="claimtype"
												defaultSelected={loadedclaimtypes.slice(0, 1)}
												onChange={setClaimTypeDash as any}
												options={loadedclaimtypes}
												placeholder="Select Case Type"
												selected={claimtypeStateDash}
												inputProps={{ required: true }}
											/>
										)}
									</div>
								)}
							</div>

							<div className="form-group p-3">
								<input
									type="checkbox"
									name="petitioner"
									checked={filterPetioner}
									onChange={toggle_petitioner}
								/>
								<span>Plaintiff/Petitioner</span>

								{filterPetioner && (
									<div className="form-group m-3">
										{/* <label htmlFor="">Case Number</label> */}
										<input
											type="text"
											className="form-control inputbordersm"
											{...register("petitioner", {
												required: true,
											})}
											placeholder="Petitioner name"
										/>
									</div>
								)}
							</div>
							<div className="form-group p-3">
								<input
									type="checkbox"
									name="firm"
									checked={representingFirm}
									onChange={toggle_firm}
								/>
								<span>Representing Firm</span>
								{representingFirm && (
									<div className="form-group p-3">
										{loadedlawfirms.length !== 0 && (
											<Typeahead
												id="basic-typeahead-double"
												clearButton
												defaultSelected={loadedlawfirms.slice(0, 1)}
												labelKey="lawfirm"
												onChange={setLawfirmDash as any}
												options={loadedlawfirms}
												placeholder="Select Law Firm"
												selected={lawfirmStateDash}
												// className="inputborder"
												size="lg"
												inputProps={{ required: true }}
											/>
										)}
									</div>
								)}
							</div>
							<button className="btn btn-primary col-12 mx-auto" type="submit">
								Search Case
							</button>
						</form>
					</div>
				</div>

				<div className="col-lg-1"></div>
				<div className="col-lg-9 nah shadow-lg p-3">
					<h3 className="text-center">Case List</h3>
					<div className="row">
						<div className="col-lg-11">
							{showAll === false && (
								<Button
									color="primary"
									className=""
									variant="contained"
									onClick={clearFilters}
								>
									Clear All Filters
								</Button>
							)}
						</div>
						<div className="col-lg-1">
							<Fab
								color="primary"
								className="mx-auto d-block"
								aria-label="add"
								onClick={openModal}
							>
								<AddIcon />
							</Fab>
							<p className="text-center text-primary">Add Case</p>
						</div>
					</div>

					{/* caselist */}
					{showAll === true && (
						<div>
							{getallcases !== null ? (
								<div>
									<h3 className="text-primary text-center">All Cases</h3>
									{/* <h1 className="text-center text-primary">All Approved Invoices List</h1> */}
									<section className="inputborder m-1">
										<div className="">
											<Table className="table table-striped table-bordered table-responsive">
												<Thead className="">
													<Tr>
														<Th className="text-left">Case No.</Th>
														<Th className="text-center">Case Type</Th>
														<Th className="text-center">Plaintiff/Petitioner</Th>
														<Th className="text-center">Representing Law Firm</Th>
														<Th className="text-left">Filing Date</Th>
														<Th className="text-left">Created On</Th>
														<Th className="text-left">Case Summary</Th>
													</Tr>
												</Thead>
												<Tbody>
													{
														// eslint-disable-next-line array-callback-return
														loadedcases.map((option: Case) => (
															<Tr key={option.caseid}>
																<Link
																	className="text-primary"
																	to={{
																		pathname: "/casedetail",
																		search: `?caseid=${option.caseid}`,
																	}}
																>
																	{option.caseno}
																</Link>
																{/* <Td style={{ verticalAlign: "middle"}}>{option.caseno}</Td> */}
																<Td style={{ verticalAlign: "middle" }}>{option.claimtype}</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{option.complainant}
																</Td>
																<Td style={{ verticalAlign: "middle" }}>{option.lawfirm}</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{DateConverter(option.filingdate)}
																</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{DateConverter(option.createdon)}
																</Td>
																<Td className="align-left" style={{ verticalAlign: "middle" }}>
																	{option.casedescription}
																</Td>
															</Tr>
														))
													}
												</Tbody>
											</Table>
										</div>
									</section>
								</div>
							) : (
								<div className="align-centre">
									<h5 className="text-center">Loading invoices, please wait...</h5>
									<Spinner />
								</div>
							)}
						</div>
					)}

					{/* FILTERED */}
					{showAll === false && (
						<div>
							{searchallcases !== null ? (
								<div>
									<h3 className="text-primary text-center">Filtered Cases</h3>
									{/* <h1 className="text-center text-primary">All Approved Invoices List</h1> */}
									<section className="inputborder m-1">
										<div className="">
											<Table className="table table-striped table-bordered table-responsive">
												<Thead className="">
													<Tr>
														<Th className="text-left">Case No.</Th>
														<Th className="text-center">Case Type</Th>
														<Th className="text-center">Plaintiff/Petitioner</Th>
														<Th className="text-center">Representing Law Firm</Th>
														<Th className="text-left">Filing Date</Th>
														<Th className="text-left">Created On</Th>
														<Th className="text-left">Case Summary</Th>
													</Tr>
												</Thead>
												<Tbody>
													{
														// eslint-disable-next-line array-callback-return
														searchallcases.map((option: Case) => (
															<Tr key={option.caseid}>
																<Link
																	className="text-primary"
																	to={{
																		pathname: "/casedetail",
																		search: `?caseid=${option.caseid}`,
																	}}
																>
																	{option.caseno}
																</Link>
																{/* <Td style={{ verticalAlign: "middle"}}>{option.caseno}</Td> */}
																<Td style={{ verticalAlign: "middle" }}>{option.claimtype}</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{option.complainant}
																</Td>
																<Td style={{ verticalAlign: "middle" }}>{option.lawfirm}</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{DateConverter(option.filingdate)}
																</Td>
																<Td style={{ verticalAlign: "middle" }}>
																	{DateConverter(option.createdon)}
																</Td>
																<Td className="align-left" style={{ verticalAlign: "middle" }}>
																	{option.casedescription}
																</Td>
															</Tr>
														))
													}
												</Tbody>
											</Table>
										</div>
									</section>
								</div>
							) : (
								<div className="align-centre">
									<h5 className="text-center">Loading cases, please wait...</h5>
									<Spinner />
								</div>
							)}
						</div>
					)}
				</div>
			</div>
			<Modal
				open={modalIsOpen}
				onClose={closeModal}
				classNames={{
					modal: "customModal",
				}}
			>
				<div className="">
					<AddCase />
				</div>
			</Modal>
		</div>
	);
};

export default Dashboard;
