/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext, useEffect } from "react";
import CaseContext from "../../context/Case/CaseContext";
import AlertContext from "../../context/alert/AlertContext";
import { useDropzone } from "react-dropzone";
import { Typeahead } from "react-bootstrap-typeahead";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import {
	getFileExtension,
	imageExtensions,
	fileExtensions,
	acceptedFiles,
} from "../../constants";

const AddActions = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const caseid = searchParams.get("caseid");
	const casecontext = useContext(CaseContext);
	const alertContext = useContext(AlertContext);

	// const { setAlert } = alertContext;
	const {
		getactiontypes,
		addactions,
		getActionTypes,
		AddActions,
		clearCaseErrors,
	} = casecontext;

	//   hook form
	const { register, handleSubmit, reset } = useForm();
	const [actiontypeState, SetActionTypeState] = useState<any[]>([]);
	const [files, setFiles] = useState<any[]>([]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		getActionTypes();
		if (addactions) {
			clearCaseErrors();
			if (alertContext) alertContext.setAlert(addactions.message, "success");
			setFiles([]);
			reset();
			setVisible(false);
		}
		// eslint-disable-next-line
	}, [reset, addactions]);

	const MAX_FILE_SIZE = 2097152;

	// Handle file drop
	const handleFileChange = (acceptedFiles: any, rejectedFiles: any) => {
		if (rejectedFiles.length > 0) {
			// Handle rejected files (size or type restrictions)
			rejectedFiles.forEach((file: any) => {
				if (alertContext) alertContext.setAlert(file.errors[0].message, "danger");
			});
		} else {
			// Handle acceptedFiles (upload, store, etc.)
			setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		}
	};

	const handleFileDrop = (index: number) => {
		setFiles((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};

	// React Dropzone configuration
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleFileChange,
		accept: acceptedFiles.join(",") as any,
		multiple: true,
		maxSize: MAX_FILE_SIZE,
	});

	let loadedactiontypes = [];
	if (getactiontypes) {
		loadedactiontypes = getactiontypes;
	} else {
		loadedactiontypes = [];
	}

	const onSubmit = (data: any) => {
		// e.preventDefault()
		if (actiontypeState.length > 0) {
			let actiontype = parseInt(actiontypeState[0].actiontypeid);
			let actiondescription = data.actiondescription;
			AddActions({
				files,
				caseid,
				actiontype,
				actiondescription,
			});
			setVisible(true);
		}
	};
	return (
		<div>
			<div className="row">
				<Alert />
				<div className="row">
					<div className="col-lg-4"></div>
					<div className="col-lg-4">
						{visible ? (
							<div className="align-centre">
								<h5 className="text-center">Adding Update...</h5>
								<Spinner />
							</div>
						) : (
							<div></div>
						)}
					</div>
					<div className="col-lg-4"></div>
				</div>
				<div className="col-lg-2"></div>
				<div className="col-lg-8 shadow-lg p-3">
					<h3 className="text-center text-primary">
						Add Update For Case Number {caseid}
					</h3>
					<hr />

					{/* form */}
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<div className="form-group p-3">
									<label htmlFor="">Update Type</label>
									{loadedactiontypes.length !== 0 && (
										<Typeahead
											id="basic-typeahead-single"
											labelKey="actiontype"
											onChange={SetActionTypeState as any}
											options={loadedactiontypes}
											placeholder="Select Update Type"
											selected={actiontypeState}
											// required
											className="inputborder"
											inputProps={{ required: true }}
										/>
									)}
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
						<div className="row">
							<div className="col-lg-2"></div>
							<div className="col-lg-8">
								<div className="form-group p-3">
									<label htmlFor="description">Update Description:</label>
									<textarea
										id="actiondescription"
										className="form-control inputborder"
										rows={4}
										required
										{...register("actiondescription", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
						{/* files */}
						<div className="form-group p-3">
							<label htmlFor="file">Files:</label>
							{files.length > 0 && (
								<div className="dropzone">
									<div className="row">
										{files.map((file, index) => (
											<div className="col-lg-6">
												<div className="dropzoneimage">
													{imageExtensions.includes(getFileExtension(file.name)) && (
														<img
															src={URL.createObjectURL(file)}
															alt="Uploaded file"
															className="img-fluid"
															// style={{ maxWidth: "20em" }}
														/>
													)}
													{fileExtensions.includes(getFileExtension(file.name)) && (
														// eslint-disable-next-line jsx-a11y/iframe-has-title
														<div className="iframe-container">
															<iframe
																key={index}
																src={URL.createObjectURL(file)}
																// width={800}
																// height={500}
																className=" responsive-iframe"
															/>
														</div>
													)}
													<div>
														<button
															type="button"
															className="btn btn-outline-danger remove-button"
															onClick={() => handleFileDrop(index)}
														>
															Remove File
														</button>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							<div {...getRootProps()} className="dropzone">
								Click to upload a file
								<input {...getInputProps()} className="form-control" multiple />
							</div>
						</div>
						{/* end of files */}
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<button className="btn btn-primary col-12 mx-auto" type="submit">
									Add Update
								</button>
							</div>
							<div className="col-lg-4"></div>
						</div>
					</form>
					{/* end of form */}
				</div>
				<div className="col-lg-2"></div>
			</div>
		</div>
	);
};

export default AddActions;
