import { useContext } from "react";
import { getPaymentTypes, PaymentType } from "@/services/Case.service";
import { useQuery } from "@tanstack/react-query";
import { UserContext } from "@/context/User/UserContext";
import { ColumnDef } from "@tanstack/react-table";
import { NavLink } from "react-router-dom";
import { STALE_TIME } from "@/constants";
import CreatePaymentType from "@/components/dialog/CreatePaymentType";
import DataTable from "@/components/table/DataTable";
import ColumnHeader from "@/components/table/ColumnHeader";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

const PaymentTypes = () => {
	const user = useContext(UserContext);

	const paymentTypes = useQuery({
		queryKey: ["payment_types"],
		queryFn: () => getPaymentTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	return (
		<div>
			<div className="flex justify-between items-center mb-4">
				<div>
					<h1 className="text-3xl font-bold">Payment Types</h1>
				</div>
				<CreatePaymentType user={user} />
			</div>
			<div className="bg-white pb-4 border rounded-md shadow-sm">
				{paymentTypes.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 4 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : paymentTypes.data ? (
					<DataTable data={paymentTypes.data} columns={Columns} />
				) : (
					<p>No data</p>
				)}
			</div>
		</div>
	);
};

export default PaymentTypes;

const Columns: ColumnDef<PaymentType>[] = [
	{
		accessorKey: "paymenttypeid",
		header: ({ column }) => (
			<ColumnHeader column={column} title="Payment Type ID" />
		),
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/payment-type/${row.original.paymenttypeid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("paymenttypeid")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "paymenttype",
		header: ({ column }) => <ColumnHeader column={column} title="Payment Type" />,
	},
	{
		accessorKey: "datacreated",
		header: ({ column }) => <ColumnHeader column={column} title="Date Created" />,
	},
];
