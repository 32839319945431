import CreateCase from "@/components/dialog/CreateCase";
import CaseTable from "@/components/table/CaseTable";
import ColumnHeader from "@/components/table/ColumnHeader";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import UpcomingEvents from "@/components/UpcomingEvents";
import { convertDateToText, ISODateFormat } from "@/constants";
import { Case } from "@/context/Case/CaseState";
import { UserContext } from "@/context/User/UserContext";
import { cn } from "@/lib/util";
import {
	getAllCases,
	getClaimTypes,
	getLawFirms,
	SearchAllCases,
	SearchAllCasesParams,
} from "@/services/Case.service";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns/format";
import { CalendarIcon, LucideSettings2 } from "lucide-react";
import { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

const CasesDashboard = () => {
	const user = useContext(UserContext);
	const [filterClaimType, setFilterClaimType] = useState<string>("");
	const [complainant, setComplainant] = useState<string>("");
	const [filingDate, setFilingDate] = useState<Date | undefined>();
	const [filterLawFirm, setFilterLawFirm] = useState<string>("");
	const [isFiltering, setIsFiltering] = useState(false);
	const [filterPopOpen, setFilterPopOpen] = useState(false);

	const cases = useQuery({
		queryKey: ["cases_all"],
		queryFn: async () => getAllCases(user?.accessToken!),
		enabled: !!user?.accessToken,
	});

	const claimTypes = useQuery({
		queryKey: ["claim_types"],
		queryFn: () => getClaimTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
	});

	const lawFirms = useQuery({
		queryKey: ["law_firms"],
		queryFn: () => getLawFirms(user?.accessToken!),
		enabled: !!user?.accessToken,
	});

	const searchCases = useMutation({
		mutationFn: async (searchQuery: SearchAllCasesParams) =>
			SearchAllCases(user?.accessToken!, searchQuery),
	});

	const getSearchResults = () => {
		setFilterPopOpen(false);
		setIsFiltering(true);
		const params = {
			...(filterClaimType && { claimtype: filterClaimType }),
			...(complainant && { complainant: complainant }),
			...(filingDate && { filingdate: ISODateFormat(filingDate) }),
			...(filterLawFirm && { lawfirm: filterLawFirm }),
		};

		console.log(params);

		searchCases.mutate(params);
	};

	return (
		<div>
			<div className="flex justify-between items-center mb-4">
				<div>
					<h1 className="text-3xl font-bold">My Dashboard</h1>
				</div>
				<CreateCase />
			</div>
			<UpcomingEvents accessToken={user?.accessToken!} />
			<h2 className="uppercase text-gray-700 font-bold tracking-wider">
				All Cases
			</h2>
			<hr className="mb-4" />
			<div className="bg-white py-4 border rounded-md shadow-sm">
				<div className="flex px-4 justify-end">
					{isFiltering ? (
						<Button onClick={() => setIsFiltering(false)} variant="ghost">
							Clear Filters
						</Button>
					) : null}
					<Popover open={filterPopOpen} onOpenChange={setFilterPopOpen}>
						<PopoverTrigger asChild>
							<Button variant="outline" className="flex px-3">
								<LucideSettings2 className="mr-2 h-4 w-4" />
								Filter
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-[250px] p-0" align="end">
							<div>
								<div className="p-2 text-sm font-medium">Filter</div>
							</div>
							<Separator />
							{claimTypes.isLoading ? (
								<p>Loading...</p>
							) : claimTypes.data ? (
								<div className="px-2 py-3">
									<div className="text-sm mb-3">Case Type</div>
									<div className="flex items-center">
										<Select value={filterClaimType} onValueChange={setFilterClaimType}>
											<SelectTrigger className="text-sm">
												<SelectValue placeholder="Select an option" />
											</SelectTrigger>
											<SelectContent className="text-xs">
												{claimTypes.data.map((item) => (
													<SelectItem
														value={item.claimtypeid.toString()}
														className="text-sm"
													>
														{item.claimtype}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
										{filterClaimType.length > 0 ? (
											<Button
												variant="ghost"
												className="p-2 ml-2 self-center"
												onClick={() => setFilterClaimType("")}
											>
												X
											</Button>
										) : null}
									</div>
								</div>
							) : null}
							<Separator />
							{lawFirms.isLoading ? (
								<p>Loading...</p>
							) : lawFirms.data ? (
								<div className="px-2 py-3">
									<div className="text-sm mb-3">Law Firm</div>
									<div className="flex items-center">
										<Select value={filterLawFirm} onValueChange={setFilterLawFirm}>
											<SelectTrigger className="text-sm">
												<SelectValue placeholder="Select an option" />
											</SelectTrigger>
											<SelectContent className="text-xs">
												{lawFirms.data.map((item) => (
													<SelectItem value={item.lawfirmid.toString()} className="text-sm">
														{item.lawfirm}
													</SelectItem>
												))}
											</SelectContent>
										</Select>
										{filterLawFirm.length > 0 ? (
											<Button
												variant="ghost"
												className="p-2 ml-2 self-center"
												onClick={() => setFilterLawFirm("")}
											>
												X
											</Button>
										) : null}
									</div>
								</div>
							) : null}
							<Separator />

							<div className="px-2 py-3">
								<p className="text-sm mb-3">Date</p>
								<div className="flex">
									<Popover>
										<PopoverTrigger asChild>
											<Button
												variant={"outline"}
												className={cn(
													"pl-3 text-left font-normal w-full",
													!filingDate && "text-muted-foreground"
												)}
											>
												{filingDate ? format(filingDate, "PPP") : <span>Pick a date</span>}
												<CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
											</Button>
										</PopoverTrigger>
										<PopoverContent className="w-auto p-0" align="start">
											<Calendar
												mode="single"
												selected={filingDate}
												onSelect={setFilingDate}
												initialFocus
											/>
										</PopoverContent>
									</Popover>
									{filingDate !== undefined ? (
										<Button
											variant="ghost"
											className="p-2 ml-2 self-center"
											onClick={() => setFilingDate(undefined)}
										>
											X
										</Button>
									) : null}
								</div>
							</div>
							<Separator />
							<div className="px-2 py-3">
								<p className="text-sm mb-3">Complainant</p>
								<div className="flex">
									<Input
										type="text"
										placeholder="Search by complainant..."
										onChange={(e) => setComplainant(e.target.value)}
										value={complainant}
									/>
									{complainant.length > 0 ? (
										<Button
											variant="ghost"
											className="p-2 ml-2 self-center"
											onClick={() => setComplainant("")}
										>
											X
										</Button>
									) : null}
								</div>
							</div>
							<div className="px-2 py-3">
								<Button className="w-full" onClick={getSearchResults}>
									Search
								</Button>
							</div>
						</PopoverContent>
					</Popover>
				</div>
				{isFiltering ? (
					searchCases.isPending ? (
						<div className="grid gap-4 pt-4">
							{Array.from({ length: 10 }).map((_, num) => (
								<Skeleton key={num} className="h-12 w-full" />
							))}
						</div>
					) : searchCases.data ? (
						<div className="pt-4 border-t mt-4">
							<CaseTable data={searchCases.data} columns={CaseColumns} />
						</div>
					) : (
						<p>No data</p>
					)
				) : cases.isLoading ? (
					<div className="grid gap-4 pt-4">
						{Array.from({ length: 10 }).map((_, num) => (
							<Skeleton key={num} className="h-12 w-full" />
						))}
					</div>
				) : cases.data ? (
					<>
						<div className="pt-4 border-t mt-4">
							<CaseTable data={cases.data} columns={CaseColumns} />
						</div>
					</>
				) : (
					<p>No data</p>
				)}
			</div>
		</div>
	);
};

export default CasesDashboard;

const CaseColumns: ColumnDef<Case>[] = [
	{
		accessorKey: "caseno",
		header: ({ column }) => <ColumnHeader column={column} title="Case No" />,
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/case/${row.original.caseid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("caseno")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "casedescription",
		header: ({ column }) => (
			<ColumnHeader column={column} title="Case Description" />
		),
		cell: ({ row }) => {
			const courtCase = row.original;
			return (
				<div className="text-sm flex gap-1 items-center">
					<span className="text-xs rounded-full px-1.5 py-0.5 font-medium text-nowrap bg-primary/10 text-primary">
						{courtCase.claimtype}
					</span>
					<p>
						{courtCase.casedescription.length > 100
							? courtCase.casedescription.slice(0, 100) + "..."
							: courtCase.casedescription}
					</p>
				</div>
			);
		},
	},
	// {
	// 	accessorKey: "overallverdict",
	// 	header: ({ column }) => (
	// 		<ColumnHeader column={column} title="Overall Verdict" />
	// 	),
	// },
	{
		accessorKey: "complainant",
		header: ({ column }) => <ColumnHeader column={column} title="Complainant" />,
	},
	{
		accessorKey: "defendingadvocate",
		header: ({ column }) => (
			<ColumnHeader column={column} title="Defending Advocate" />
		),
	},
	{
		accessorKey: "lawfirm",
		header: ({ column }) => <ColumnHeader column={column} title="Law Firm" />,
	},
	{
		accessorKey: "createdon",
		header: ({ column }) => <ColumnHeader column={column} title="Created On" />,
		cell: ({ row }) => {
			return (
				<div className="font-medium">
					{convertDateToText(row.getValue("createdon"))}
				</div>
			);
		},
	},
	{
		accessorKey: "createdby",
		header: ({ column }) => <ColumnHeader column={column} title="Created By" />,
	},
];
