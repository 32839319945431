import { Button } from "@/components/ui/button";
import {
	Sheet,
	SheetClose,
	SheetContent,
	SheetFooter,
	SheetHeader,
	SheetTitle,
} from "@/components/ui/sheet";
import { convertDateToText, convertToTimeFormat } from "@/constants";
import { Action, UpcomingEvent } from "@/services/Case.service";
import { BiCalendar, BiTagAlt, BiUser } from "react-icons/bi";
import { CgNotes } from "react-icons/cg";
import { RiAttachment2 } from "react-icons/ri";
import AttachmentDetails from "../AttachmentDetails";
import { BsBuildingsFill } from "react-icons/bs";

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	event: UpcomingEvent | null;
};

const DisplayUpcomingEvent: React.FC<Props> = ({ open, setOpen, event }) => {
	if (!event) return null;

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetContent className="sm:max-w-2xl">
				<SheetHeader>
					<SheetTitle className="text-3xl">
						<span className="text-gray-600 font-bold mr-2">{`#${event.actionid}`}</span>
						<span className="font-semibold">
							{event.actiondate}
						</span>
					</SheetTitle>
				</SheetHeader>
				<div className="grid py-4 gap-4">
					<div className="grid grid-cols-4 gap-y-4 gap-x-1">
						<div className="col-span-1 flex gap-6">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiTagAlt className="text-lg mr-2" />
								Event Type
							</span>
						</div>
						<div className="col-span-3">
							<div className="flex-1 w-5/6">
								<span className="text-sm text-blue-700 bg-blue-100 border border-blue-300 rounded-sm font-semibold px-2 py-1 self-start">
									{event.actiontype}
								</span>
							</div>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiCalendar className="text-lg mr-2" />
								Action Date
							</span>
						</div>
						<div className="col-span-3">
							<span>{event.actiondate}</span>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiUser className="text-lg mr-2" />
								Petitioner
							</span>
						</div>
						<div className="col-span-3">
							<span>{event.petitioner}</span>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiUser className="text-lg mr-2" />
								Defending Advocate
							</span>
						</div>
						<div className="col-span-3">
							<span>{event.defendingadvocate}</span>
						</div>
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BsBuildingsFill className="text-lg mr-2" />
								Law Firm
							</span>
						</div>
						<div className="col-span-3">
							<span>{event.lawfirm}</span>
						</div>
						{event.previousactiontype && event.previousactiondescription ? (
							<>
								<div className="col-span-1 flex flex-col gap-4">
									<span className="inline-flex items-center font-light text-gray-600">
										<BiCalendar className="text-lg mr-2" />
										Previous Event Type
									</span>
								</div>
								<div className="col-span-3">
									<span>{event.previousactiontype}</span>
								</div>
								<div className="col-span-1 flex flex-col gap-4">
									<span className="inline-flex items-center font-light text-gray-600">
										<CgNotes className="text-lg mr-2" />
										Previous Event Description
									</span>
								</div>
								<div className="col-span-4">
									<span>{event.previousactiondescription}</span>
								</div>
							</>
						) : null}
						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<BiUser className="text-lg mr-2" />
								Case No
							</span>
						</div>
						<div className="col-span-3">
							<span>{event.caseno}</span>
						</div>

						<div className="col-span-1 flex flex-col gap-4">
							<span className="inline-flex items-center font-light text-gray-600">
								<CgNotes className="text-lg mr-2" />
								Case Description
							</span>
						</div>
						<div className="col-span-4">
							<span>{event.casedescription}</span>
						</div>
					</div>
				</div>
				<SheetFooter className="sm:justify-start">
					<SheetClose asChild>
						<Button type="submit">Done</Button>
					</SheetClose>
				</SheetFooter>
			</SheetContent>
		</Sheet>
	);
};

export default DisplayUpcomingEvent;
